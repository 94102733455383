import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// hooks
import { useGetNotifications } from "hooks/api/shared";

// packages
import moment from "moment";

const Notification = ({ toggleNotification }) => {
  const history = useHistory();
  const { data: notifications } = useGetNotifications();

  return (
    <ul
      className={
        toggleNotification
          ? "dropdown-menu notifications show"
          : "dropdown-menu notifications"
      }
    >
      <li className="header">
        <strong>Recent Notifications</strong>
      </li>
      {notifications?.data?.slice(0, 4)?.map((ele) => (
        <li>
          <a>
            <div className="media">
              <div className="media-left">
                <i className="icon-user text-success"></i>
              </div>
              <div className="media-body">
                <p className="text">{ele.text}</p>
                <span className="timestamp">
                  {moment(ele.created_at).fromNow()}
                </span>
              </div>
            </div>
          </a>
        </li>
      ))}
      <li className="footer">
        <a
          className="more"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/myinbox")}
        >
          See all notifications
        </a>
      </li>
    </ul>
  );
};

Notification.propTypes = {
  toggleNotification: PropTypes.bool.isRequired,
};

export default Notification;
