import React, { useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";
import ImageFile from "components/FormField/ImageFile";

// packages
import _ from "lodash";
import { useForm } from "react-hook-form";

// api's hook
import {
  useCreateQuestion,
  useGetBoards,
  useGetChapters,
  useGetClasses,
  useGetQuestion,
  useGetSubjects,
  useGetTopics,
  useUpdateQuestion,
} from "hooks/api/subject";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateQuestion = () => {
  const { id } = useParams();

  const [valueAdded, setValueAdded] = React.useState(false);
  const [render, setRender] = React.useState(id ? false : true);
  const [image, setImage] = React.useState({});
  // const editorRef = useRef();

  // useEffect(() => {
  //   if (editorRef.current) {
  //     editorRef.current.ui.view.editable.extendTemplate({
  //       attributes: {
  //         class: "equation",
  //       },
  //     });
  //   }
  // }, []);

  const { data: question } = useGetQuestion(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: chapters } = useGetChapters();
  const { data: topics } = useGetTopics();

  const createQuestion = useCreateQuestion();
  const updateQuestion = useUpdateQuestion();

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage({
      file,
      name: file.name,
      previewUrl: URL.createObjectURL(file),
    });
  };

  async function onSubmit(data) {
    try {
      let body = _.pick(data, [
        "boardId",
        "classId",
        "subjectId",
        "chapterId",
        "topicId",
        "type",
        "marks",
        "question",
      ]);

      const formData = new FormData();
      formData.append(`image`, image?.file || null);
      Object.entries(body).map(([key, value], index) =>
        formData.append(`${key}`, value)
      );

      if (id) {
        formData.append(
          `status`,
          question?.data[0]?.status == 2 || question?.data[0]?.status == 3
            ? 3
            : question?.data[0]?.status
        );
        await updateQuestion.mutateAsync({
          id,
          body: formData,
        });
      } else {
        // formData.append(`status`, 0);
        await createQuestion.mutateAsync(formData);
      }
    } catch (err) {}
  }

  const watchBoard = watch("boardId");
  const watchClass = watch("classId");
  const watchSubject = watch("subjectId");
  const watchChapter = watch("chapterId");

  useEffect(() => {
    valueAdded && setValue("chapterId", "");
  }, [watchBoard, watchClass, watchSubject]);

  useEffect(() => {
    valueAdded && setValue("topicId", "");
  }, [watchBoard, watchClass, watchSubject, watchChapter]);

  useEffect(() => {
    if (
      !!id &&
      !!render &&
      !!Object.values(watch()).length > 0 &&
      question?.data?.[0]
    ) {
      setValueAdded(true);
    }

    if (!id && !!render) {
      setValueAdded(true);
    }
  }, [watch()]);

  const chaptersOptions = useMemo(() => {
    let temp = [];
    if (watchBoard && watchClass && watchSubject) {
      for (let i = 0; i < chapters?.data?.length; i++) {
        if (
          chapters?.data[i].board_id == watchBoard &&
          chapters?.data[i].class_id == watchClass &&
          chapters?.data[i].subject_id == watchSubject
        ) {
          temp.push({
            label: chapters?.data[i].name,
            value: chapters?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [chapters?.data, watchBoard, watchClass, watchSubject]);

  const topicOptions = useMemo(() => {
    let temp = [];
    if (watchChapter) {
      for (let i = 0; i < topics?.data?.length; i++) {
        if (
          topics?.data[i].board_id == watchBoard &&
          topics?.data[i].class_id == watchClass &&
          topics?.data[i].subject_id == watchSubject &&
          topics?.data[i].chapter_id == watchChapter
        ) {
          temp.push({
            label: topics?.data[i].name,
            value: topics?.data[i].id,
          });
        }
      }
    }
    return temp;
  }, [topics?.data, watchChapter]);

  React.useEffect(() => {
    if (question?.data?.[0]) {
      let values = _.pick(question?.data?.[0], [
        "board_id",
        "class_id",
        "subject_id",
        "chapter_id",
        "topic_id",
        "type",
        "marks",
        "question",
        "status",
        "file",
      ]);

      values = {
        ...values,
        boardId: values.board_id,
        classId: values.class_id,
        subjectId: `${values.subject_id}`,
        chapterId: values.chapter_id,
        topicId: values.topic_id || null,
        type: values.type,
        marks: values.marks,
        question: values.question,
        status: question?.data[0]?.status,
      };

      setImage({
        previewUrl: values.file
          ? `${process.env.REACT_APP_API_BASE_URL}/uploads/${values.file}`
          : null,
      });

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
      setRender(true);
    }
  }, [question]);

  const isCKEditor =
    [5, 6, 8, 12, 13, 15].filter((id) => id == Number(watchSubject)).length > 0
      ? true
      : false;

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Chapter"
        Breadcrumb={[
          { name: "Questions", navigate: "/questions" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Question</h2>
        </div>

        {/* <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue="<p>This is the initial content of the editor.</p>"
          init={{
            height: 500,
            menubar: false,
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | " +
              "undo redo | casechange blocks | bold italic backcolor | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            draggable_modal: true,
            external_plugins: {
              tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
            },
          }}
        /> */}

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.boardId}
                item={{
                  name: "boardId",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.classId}
                item={{
                  name: "classId",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subjectId}
                item={{
                  name: "subjectId",
                  label: "Subject",
                  options: subjects?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.chapterId}
                item={{
                  name: "chapterId",
                  label: "Chapter",
                  options: chaptersOptions,
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.topicId}
                item={{
                  name: "topicId",
                  label: "Topic",
                  options: topicOptions,
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.type}
                item={{
                  name: "type",
                  label: "Type",
                  options: [
                    { label: "Short", value: "short" },
                    { label: "Long", value: "long" },
                  ],
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.marks}
                item={{
                  type: "number",
                  name: "marks",
                  label: "Marks",
                  placeholder: "Enter Marks",
                  rules,
                }}
              />

              {!!id && (
                <FormSelect
                  control={control}
                  error={formState?.errors?.status}
                  item={{
                    name: "status",
                    label: "Status",
                    disabled: true,
                    options: [
                      { value: 0, label: "Deactivate" },
                      { value: 1, label: "Active" },
                      { value: 2, label: "Feedback" },
                      { value: 3, label: "Resolved" },
                    ],
                    rules,
                  }}
                />
              )}
            </div>
            <div className="row clearfix">
              <div className="col-12 mb-4">
                <FormTextEditor
                  isCKEditor={isCKEditor}
                  control={control}
                  error={formState?.errors?.question}
                  item={{
                    name: "question",
                    label: "Question",
                    placeholder: "Write",
                    required: true,
                  }}
                />
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-6  mb-4">
                <ImageFile image={image} onChange={handleImageChange} />
              </div>
              {!!image?.previewUrl && (
                <div className="col-6 mb-4" style={{ position: "relative" }}>
                  <button
                    type="button"
                    title="Close"
                    className="btn btn-warning"
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                    }}
                    onClick={() => setImage({})}
                  >
                    <span className="sr-only">Close</span>
                    <i className="fa fa-close"></i>
                  </button>
                  <img
                    src={image?.previewUrl}
                    alt="image"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              )}
            </div>

            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateQuestion;
