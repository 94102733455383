import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import {
  ComputerStudiesMCQSFormat,
  EnglishMCQSFormat,
  MCQSFormat,
  PakStudiesMCQSFormat,
} from "./MCQS";
import {
  LongEnglishQuestions,
  LongPakStudiesQuestions,
  LongQuestions,
  ShortQuestions,
} from "./Questions";
import PaperStudentEntryPunjab from "./PaperStudentEntryPunjab";

const PunjabPaperFormat = ({ data, showMcqAnswer }) => {
  console.log("data: ", data);
  const isEnglish = ["1", "11"].includes(
    `${data?.paper_details[0]?.subject_id}`
  );

  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  const isMcqExist = !!(data?.paperMcqs.length > 0);

  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );
  const isPunjab = data?.paper_details[0].board_type == "Punjab";
  const subjectCheck = data?.paper_details[0]?.subject_id;
  return (
    <div style={{ color: "black", fontSize: "22px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {subjectCheck === 16 || subjectCheck === 18 ? (
              <PaperStudentEntryPunjab
                subjectCheck={subjectCheck}
                data={data}
              />
            ) : (
              <PaperStudentEntry data={data} />
            )}

            <div className="row clearfix font-weight-bold mb-3">
              {subjectCheck === 16 ? (
                <>
                  <div className="col-6">
                    Time Allowed: {data?.paper_details[0].objective_time}
                  </div>
                  <div className="col-6 text-right">
                    Marks: {data?.paper_details[0].objective_marks}
                  </div>
                </>
              ) : (
                <>
                  {subjectCheck === 18 ? (
                    <>
                      <div className="col-6">
                        Time: {data?.paper_details[0].objective_time}
                      </div>
                      <div className="col-6 text-right">
                        Maximum Marks: {data?.paper_details[0].objective_marks}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-4">
                        Time: {data?.paper_details[0].objective_time}
                      </div>
                      <div className="col-4 text-center">OBJECTIVE</div>
                      <div className="col-4 text-right">
                        Total Marks: {data?.paper_details[0].objective_marks}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {subjectCheck === 18 && (
              <div className="row clearfix font-weight-bold ">
                <div className="col-12 text-center">OBJECTIVE TYPE</div>
              </div>
            )}
            <div className="mb-3">
              <span className="">Note:- </span>
              {data?.paper_details[0].objective_note}
            </div>
            {subjectCheck === 16 && (
              <div className="row clearfix font-weight-bold mb-3">
                <div className="col-12 text-decoration-cls text-center">
                  OBJECTIVE
                </div>
              </div>
            )}

            {isPunjab && isEnglish ? (
              <EnglishMCQSFormat data={data} />
            ) : (
              <>
                {isPunjab && subjectCheck === 16 ? (
                  <PakStudiesMCQSFormat data={data} />
                ) : (
                  <>
                    {isPunjab && subjectCheck === 18 ? (
                      <ComputerStudiesMCQSFormat data={data} />
                    ) : (
                      <MCQSFormat data={data} />
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          {subjectCheck === 18 && (
            <PaperStudentEntryPunjab subjectCheck={subjectCheck} data={data} />
          )}

          <div className="row clearfix font-weight-bold mb-3">
            {subjectCheck === 16 ? (
              <>
                <div className="col-6">
                  Time Allowed: {data?.paper_details[0].subjective_time}
                </div>
                <div className="col-6 text-right">
                  Marks: {data?.paper_details[0].subjective_marks}
                </div>
              </>
            ) : (
              <>
                {subjectCheck === 18 ? (
                  <>
                    <div className="col-6">
                      Total Marks: {data?.paper_details[0].subjective_marks}
                    </div>
                    <div className="col-6 text-right">
                      Time: {data?.paper_details[0].subjective_time}
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="col-4">
                      Time: {data?.paper_details[0].subjective_time}
                    </div>
                    <div className="col-4 text-center">SUBJECTIVE</div>
                    <div className="col-4 text-right">
                      Total Marks: {data?.paper_details[0].subjective_marks}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          {subjectCheck === 18 && (
            <div className="row clearfix font-weight-bold ">
              <div className="col-12 text-center">Essay TYPE</div>
            </div>
          )}

          {!!data?.section[0]?.text && (
            <div className="mb-3">
              <span className="font-weight-bold">Note:- </span>
              {data?.section[0]?.text}
            </div>
          )}

          {isShortQuestionsExist && !isMath && (
            <div className="text-center font-weight-bold">
              {isEnglish
                ? "SECTION-I"
                : subjectCheck === 16
                ? "SECTION I"
                : subjectCheck === 18
                ? "(PART-I)"
                : "PART-I"}
            </div>
          )}

          {isShortQuestionsExist && !!isMath && (
            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-3 text-right" />
              <div className="col-6 text-center">PART-I</div>
              <div className="col-3 text-right">{data?.section[1].marks}</div>
            </div>
          )}
          <ShortQuestions
            subject_id={data?.paper_details[0]?.subject_id}
            data={data?.section[0]}
            isMcqExist={isMcqExist}
          />

          {isLongQuestionsExist && (
            <div className="text-center font-weight-bold mt-4 mb-2">
              {isEnglish
                ? "SECTION-II"
                : subjectCheck === 16
                ? "SECTION II"
                : subjectCheck === 18
                ? "(PART-II)"
                : "PART-II"}
            </div>
          )}

          {isPunjab && isEnglish ? (
            <LongEnglishQuestions
              data={data?.section[1]}
              startIndex={data?.section[0]?.paperShortQuestions?.length || 0}
              isMcqExist={isMcqExist}
            />
          ) : (
            <>
              {(isPunjab && subjectCheck === 16) || 18 ? (
                <>
                  <div className="row clearfix  mb-3">
                    <div className="col-9">
                      <span className="font-weight-bold">Note:</span>{" "}
                      {data?.section[1].text}
                    </div>
                    <div
                      className={`${
                        subjectCheck === 18 && "font-weight-bold"
                      } col-3 text-right`}
                    >
                      {data?.section[1].marks}
                    </div>
                  </div>
                  <LongPakStudiesQuestions
                    subjectCheck={subjectCheck}
                    data={data?.section[1]}
                    startIndex={
                      data?.section[0]?.paperShortQuestions?.length || 0
                    }
                    isMcqExist={isMcqExist}
                  />
                </>
              ) : (
                <>
                  <div className="row clearfix  mb-3">
                    <div className="col-9">
                      <span className="font-weight-bold">Note:</span>{" "}
                      {data?.section[1].text}
                    </div>
                    <div className="col-3 text-right">
                      {data?.section[1].marks}
                    </div>
                  </div>
                  <LongQuestions
                    subject_id={data?.paper_details[0]?.subject_id}
                    data={data?.section[1]}
                    startIndex={
                      data?.section[0]?.paperShortQuestions?.length || 0
                    }
                    isMcqExist={isMcqExist}
                  />
                </>
              )}
            </>
          )}
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            {subjectCheck === 16 ? (
              <PaperStudentEntryPunjab data={data} />
            ) : (
              <PaperStudentEntry data={data} />
            )}

            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-4">
                Time: {data?.paper_details[0].objective_time}
              </div>
              <div className="col-4 text-center">OBJECTIVE ANSWER SHEET</div>
              <div className="col-4 text-right">
                Total Marks: {data?.paper_details[0].objective_marks}
              </div>
            </div>

            <div className="mb-3">
              <span className="font-weight-bold">Note:- </span>
              {data?.paper_details[0].objective_note}
            </div>

            {isEnglish ? (
              <EnglishMCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            ) : (
              <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PunjabPaperFormat;
