import React, { useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";
import ImageFile from "components/FormField/ImageFile";

// packages
import _ from "lodash";
import { useForm } from "react-hook-form";

// api's hook
import { useGetBoards, useGetClasses, useGetSubjects } from "hooks/api/subject";
import { useAuthContext } from "context/auth.context";
import {
  useCreateNumberOfQuiz,
  useGetNumberOfQuiz,
  useUpdateNumberOfQuiz,
} from "hooks/api/students";
import QuizChapters from "./QuizChapters";
import CreateUpdateQuizChapters from "./CreateUpdateQuizChapters";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateQuizChapters = () => {
  const { user } = useAuthContext();
  const { id, board_id, class_id, subject_id } = useParams();

  const [valueAdded, setValueAdded] = React.useState(false);
  const [render, setRender] = React.useState(id ? false : true);

  const { data: subjects } = useGetSubjects();
  const { data: numberOfQuiz } = useGetNumberOfQuiz(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();

  const createNumberOfQuiz = useCreateNumberOfQuiz();
  const updateNumberOfQuiz = useUpdateNumberOfQuiz();

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm();

  const [numOfQuizzes, setNumOfQuizzes] = useState(0);
  const [editId, setEditId] = useState("");

  const [existingQuizNumbers, setExistingQuizNumbers] = useState([]);
  const [addUpdate, setAddUpdate] = useState(true);
  async function onSubmit(data) {
    try {
      let body = _.pick(data, [
        "num_of_quiz",
        "board_id",
        "class_id",
        "subject_id",
      ]);

      const formData = new FormData();
      Object.entries(body).map(([key, value], index) =>
        formData.append(`${key}`, value)
      );

      if (id) {
        await updateNumberOfQuiz.mutateAsync({
          id,
          body: body,
        });
      } else {
        await createNumberOfQuiz.mutateAsync(body);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (
      !!id &&
      !!render &&
      !!Object.values(watch()).length > 0 &&
      numberOfQuiz?.data?.[0]
    ) {
      setValueAdded(true);
    }

    if (!id && !!render) {
      setValueAdded(true);
    }
  }, [watch()]);

  useEffect(() => {
    if (id) {
      if (numberOfQuiz?.data?.[0]) {
        let values = _.pick(numberOfQuiz?.data?.[0], [
          "num_of_quiz",
          "subject_id",
          "class_id",
          "board_id",
        ]);

        values = {
          ...values,
          num_of_quiz: values.num_of_quiz,
          subject_id: values.subject_id,
          board_id: values.board_id,
          class_id: values.class_id,
        };

        Object.entries(values).forEach(([key, val]) => {
          setValue(`${key}`, val);
        });
        setNumOfQuizzes(values.num_of_quiz);
      }
    } else {
      reset();
    }
    setRender(true);
  }, [id, numberOfQuiz, reset, setValue]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Chapter"
        Breadcrumb={[
          { name: "Quizzes Chapters", navigate: "/quizzes/number-of-quizzes" },
          { name: "Quizzes Chapters" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>Quizzes Data</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.num_of_quiz}
                item={{
                  type: "number",
                  name: "num_of_quiz",
                  label: "Number of Quizzes",
                  placeholder: "Enter Number of Quizzes",
                  rules,
                  disabled: true,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.board_id}
                item={{
                  name: "board_id",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                  disabled: true,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.class_id}
                item={{
                  name: "class_id",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                  disabled: true,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subject_id}
                item={{
                  name: "subject_id",
                  label: "Subject",
                  options: subjects?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                  disabled: true,
                }}
              />
            </div>
          </form>
        </div>
      </div>

      {(addUpdate || editId) && (
        <CreateUpdateQuizChapters
          setAddUpdate={setAddUpdate}
          addUpdate={addUpdate}
          board_id={board_id}
          setEditId={setEditId}
          editId={editId}
          class_id={class_id}
          subject_id={subject_id}
          num_of_quiz={numOfQuizzes}
          existingQuizNumbers={existingQuizNumbers}
        />
      )}

      <QuizChapters
        setAddUpdate={setAddUpdate}
        addUpdate={addUpdate}
        setEditId={setEditId}
        editId={editId}
        board_id={board_id}
        class_id={class_id}
        subject_id={subject_id}
        setExistingQuizNumbers={setExistingQuizNumbers}
      />
    </div>
  );
};

export default CreateQuizChapters;
