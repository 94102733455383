import React, { useState, useEffect } from "react";
import { useParams , useHistory} from "react-router-dom";

// packages
import parse from "html-react-parser";

// components
import PageHeader from "components/BreadCrumb";
import EntryFeedBackField from "components/EntryFeedBackField";

// hooks
import { useGetTopic, useGetTopics, useReviewTopic } from "hooks/api/subject";

// context
import { useAuthContext } from "context/auth.context";

//
import { dataReviewUser } from "constant";

const TopicDetail = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const history = useHistory();
  const [state, setState] = useState({});

  const { data: topic, refetch } = useGetTopic(id);
  const [rows, setRows] = useState([]);
  const { data: topics } = useGetTopics();
  const reviewTopic = useReviewTopic();


  useEffect(() => {
    if (topics?.data) {
      setRows(topics?.data);
    }
  }, [topics]);

  const getCurrentTopicsIndex = () => {
    return rows.findIndex((topic) => topic.id == id);
  };

  const navigateToTopics = (newIndex) => {
    if (newIndex >= 0 && newIndex < rows.length) {
      const newTopicsId = rows[newIndex].id;
      history.push(`/topics/${newTopicsId}`);
    }
  };
  const onNext = () => {
    const currentIndex = getCurrentTopicsIndex();
    if (currentIndex !== -1 && currentIndex < rows.length - 1) {
      navigateToTopics(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    const currentIndex = getCurrentTopicsIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      navigateToTopics(currentIndex - 1);
    }
  };

  const onSave = async () => {
    const body = { ...state };
    await reviewTopic.mutateAsync({ id, body });
    refetch();
  };

  useEffect(() => {
    if (topic?.data) {
      setState({
        status: topic?.data[0]?.review_status || 0,
        feedback: topic?.data[0]?.feeback || "",
      });
    }
  }, [topic]);

  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Topics", navigate: "/topics" },
          { name: "Detail" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>Topic Detail</h2>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Board:</th>
                <td>{topic?.data[0].board_name}</td>
              </tr>
              <tr>
                <th scope="row">Subject:</th>
                <td>{topic?.data[0].subjectname}</td>
              </tr>
              <tr>
                <th scope="row">Class:</th>
                <td>{topic?.data[0].classname}</td>
              </tr>
              <tr>
                <th scope="row">Chapter Name:</th>
                <td>{topic?.data[0].chapter_name}</td>
              </tr>
              <tr>
                <th scope="row">Topic Name:</th>
                <td>{topic?.data[0].name}</td>
              </tr>
              <tr>
                <th scope="row">Topic Description:</th>
                <td>{parse(`${topic?.data[0].notes}`)}</td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{topic?.data[0].review_status}</td>
              </tr>
              <tr>
                <th scope="row">Feedback:</th>
                <td>{topic?.data[0].feeback}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="body">
          <button
            type="submit"
            className="btn btn-success ml-auto"
            onClick={onPrevious}
            disabled={getCurrentTopicsIndex() <= 0}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-success mx-3"
            onClick={onNext}
            disabled={getCurrentTopicsIndex() >= rows.length - 1}
          >
            Next
          </button>
        </div>

        {user.rolename.toLowerCase() === dataReviewUser && (
          <EntryFeedBackField
            state={state}
            setState={setState}
            onSave={onSave}
          />
        )}
      </div>
    </div>
  );
};

export default TopicDetail;
