// third party
import { useMutation, useQuery } from "@tanstack/react-query";

// hooks
import {
  createNotification,
  getNotifications,
  getRegions,
  getUserGroups,
} from "api/v1/shared";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetRegions = () => {
  return useQuery({
    queryKey: ["campus-regions"],
    queryFn: () => getRegions(),
    refetchOnWindowFocus: false,
  });
};

export const useGetSchools = () => {
  return useQuery({
    queryKey: ["schools"],
    queryFn: () => getUserGroups(),
    refetchOnWindowFocus: false,
  });
};

export const useCreateNotification = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createNotification,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useGetNotifications = () => {
  return useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotifications(),
    refetchOnWindowFocus: false,
  });
};
