import React, { useEffect, useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";
import ImageFile from "components/FormField/ImageFile";

// packages
import _ from "lodash";
import { useForm } from "react-hook-form";

// api's hook
import { useGetBoards, useGetClasses } from "hooks/api/subject";
import { useAuthContext } from "context/auth.context";
import {
  useCreateStudent,
  useGetBoardCampus,
  useGetSessions,
  useGetStudent,
  useUpdateStudent,
} from "hooks/api/students";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateStudent = () => {
  const { user } = useAuthContext();
  const { id } = useParams();

  const [valueAdded, setValueAdded] = React.useState(false);
  const [render, setRender] = React.useState(id ? false : true);

  const { data: student } = useGetStudent(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: sessions } = useGetSessions();

  const createStudent = useCreateStudent();
  const updateStudent = useUpdateStudent();

  const { handleSubmit, reset, watch, setValue, control, formState } =
    useForm();
  const watchBoard = watch("boardType");
  const { data: campus, refetch, isLoading } = useGetBoardCampus(watchBoard);

  useEffect(() => {
    if (watchBoard) {
      refetch();
    }
  }, [watchBoard, refetch]);

  async function onSubmit(data) {
    try {
      let body = _.pick(data, [
        "name",
        "email",
        "phone",
        "boardType",
        "campusType",
        "sessionType",
        "classType",
        "subjectType",
      ]);
      body.instituteType = user.user_group_id;

      const formData = new FormData();
      Object.entries(body).map(([key, value], index) =>
        formData.append(`${key}`, value)
      );
      if (id) {
        await updateStudent.mutateAsync({
          id,
          body: body,
        });
      } else { 
        await createStudent.mutateAsync(body);
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (
      !!id &&
      !!render &&
      !!Object.values(watch()).length > 0 &&
      student?.data?.[0]
    ) {
      setValueAdded(true);
    }

    if (!id && !!render) {
      setValueAdded(true);
    }
  }, [watch()]);

 
  useEffect(() => {
    if (id) {
      if (student?.data?.[0]) {
        let values = _.pick(student?.data?.[0], [
          "name",
          "email",
          "phone",
          "instituteType",
          "subjectType",
          "sessionType",
          "classType",
          "campusType",
          "boardType",
        ]);

        values = {
          ...values,
          name: values.name,
          email: values.email,
          phone: values.phone,
          instituteType: values.instituteType,
          subjectType: values.subjectType,
          boardType: values.boardType,
          campusType: values.campusType,
          sessionType: values.sessionType,
          classType: values.classType,
        };

        Object.entries(values).forEach(([key, val]) => {
          setValue(`${key}`, val);
        });
      }
    } else {
      reset();
    }
    setRender(true);
  }, [id, student, reset, setValue]);



  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Chapter"
        Breadcrumb={[
          { name: "Students", navigate: "/students" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Students</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  type: "text",
                  name: "name",
                  label: "Name",
                  placeholder: "Enter Name",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.email}
                item={{
                  type: "email",
                  name: "email",
                  label: "Email",
                  placeholder: "Enter Email",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.phone}
                item={{
                  type: "number",
                  name: "phone",
                  label: "Mobile Number",
                  placeholder: "Enter Number",
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.sessionType}
                item={{
                  name: "sessionType",
                  label: "Session",
                  options: sessions?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.session,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.boardType}
                item={{
                  name: "boardType",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.campusType}
                item={{
                  name: "campusType",
                  label: "Campus",
                  options: campus?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.classType}
                item={{
                  name: "classType",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subjectType}
                item={{
                  name: "subjectType",
                  label: "Subject",
                  options: [
                    { label: "Computer", value: 18 },
                    { label: "Biology", value: 7 },
                  ],
                  rules,
                }}
              />
            </div>

            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateStudent;
