// third party
import { useQuery, useMutation } from "@tanstack/react-query";

// hooks
import {
  getCampusUsers,
  getCampusUser,
  createCampusUser,
  deleteCampusUser,
  updateCampusUser,
} from "api/v1/campusUser";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useCreateCampusUser = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createCampusUser,
    onSuccess: () => toasterSuccess("Campus user created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useGetCampusUsers = () => {
  return useQuery({
    queryKey: ["campus-users"],
    queryFn: () => getCampusUsers(),
    refetchOnWindowFocus: false,
  });
};

export const useGetCampusUser = (id) => {
  return useQuery({
    queryKey: ["campus-users", id],
    queryFn: () => getCampusUser(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};

export const useUpdateCampusUser = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateCampusUser,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteCampusUser = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteCampusUser,
    onSuccess: () => {
      refetch();
      toasterSuccess("Campus user deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
