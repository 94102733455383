import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-dropdown-select";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useAuthContext } from "context/auth.context";
import {
  useCreateNumberOfQuiz,
  useCreateQuizChapters,
  useGetNumberOfQuiz,
  useGetSingleQuizChapter,
  useUpdateNumberOfQuiz,
  useUpdateQuizChapters,
} from "hooks/api/students";
import {
  useGetBoards,
  useGetClasses,
  useGetQuizMChapters,
  useGetSubjects,
} from "hooks/api/subject";
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";
import FormTextEditor from "components/FormField/TextEditor";
import ImageFile from "components/FormField/ImageFile";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateUpdateQuizChapters = ({
  board_id,
  class_id,
  subject_id,
  setAddUpdate,
  addUpdate,
  setEditId,
  num_of_quiz,
  existingQuizNumbers,
  editId,
}) => {
  const { user } = useAuthContext();
  const id = editId;
  const [valueAdded, setValueAdded] = React.useState(false);
  const [render, setRender] = React.useState(id ? false : true);
  const { data: quizChapter, refetch } = useGetSingleQuizChapter(id);
  const { data: chapters } = useGetQuizMChapters(
    board_id,
    class_id,
    subject_id
  );

  useEffect(() => {
    if (editId) {
      refetch();
    }
  }, [editId, refetch]);

  const createNumberOfQuiz = useCreateQuizChapters();
  const updateNumberOfQuiz = useUpdateQuizChapters();

  const { handleSubmit, reset, watch, setValue, control, register, formState } =
    useForm();

  async function onSubmit(data) {
    try {
      let body = _.pick(data, [
        "quiz_number",
        "board_id",
        "class_id",
        "subject_id",
        "chapters_id",
      ]);
      body.board_id = board_id;
      body.class_id = class_id;
      body.subject_id = subject_id;

      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) =>
        formData.append(key, value)
      );

      if (id) {
        const created = await updateNumberOfQuiz.mutateAsync({
          id,
          body: body,
        });
        if (created?.statusCode === 200) {
          setAddUpdate(false);
          setEditId("");
        }
      } else {
        const created = await createNumberOfQuiz.mutateAsync(body);
        if (created?.statusCode === 200) {
          setAddUpdate(false);
          setEditId("");
        }
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (
      !!id &&
      !!render &&
      !!Object.values(watch()).length > 0 &&
      quizChapter?.data?.[0]
    ) {
      setValueAdded(true);
    }

    if (!id && !!render) {
      setValueAdded(true);
    }
  }, [watch()]);

  useEffect(() => {
    if (id) {
      if (quizChapter?.data?.[0]) {
        let values = _.pick(quizChapter?.data?.[0], [
          "quiz_number",
          "subject_id",
          "class_id",
          "board_id",
          "chapters_id",
        ]);

        values = {
          ...values,
          quiz_number: values.quiz_number,
          subject_id: values.subject_id,
          board_id: values.board_id,
          class_id: values.class_id,
          chapters_id: values.chapters_id,
        };

        Object.entries(values).forEach(([key, val]) => {
          setValue(key, val);
        });
      }
    } else {
      reset();
    }
    setRender(true);
  }, [id, quizChapter, reset, setValue]);

  const generateOptions = () => {
    const options = [];
    for (let i = 1; i <= num_of_quiz; i++) {
      options.push({
        value: i,
        label: `Quiz ${i}`,
        disabled: existingQuizNumbers.includes(i.toString()),
      });
    }
    return options;
  };

  useEffect(() => {
    if (quizChapter?.data?.[0]?.chapters_id) {
      const selectedChapterIds = JSON.parse(quizChapter.data[0].chapters_id);
      setValue("chapters_id", selectedChapterIds);
    }
  }, [quizChapter, setValue]);

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Quiz Chapters </h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.quiz_number}
                item={{
                  name: "quiz_number",
                  label: "Quiz Number",
                  options: generateOptions(),
                  disabled: id ? true : false,
                  rules,
                }}
              />

              <Select
                className="js-states"
                style={{ width: "100%", marginBottom: "0.5rem" }}
                placeholder="Select Chapters"
                options={chapters?.data?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
                multi={true}
                searchable={true}
                onChange={(values) => {
                  setValue(
                    "chapters_id",
                    values.map((ele) => ele.value)
                  );
                }}
                value={chapters?.data
                  ?.filter((chapter) =>
                    watch("chapters_id")?.includes(chapter.id)
                  )
                  .map((chapter) => ({
                    value: chapter.id,
                    label: chapter.name,
                  }))}
              />
            </div>

            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUpdateQuizChapters;
