import React, { useState, useEffect } from "react";

// api's hook
import {
  useGetBoards,
  useGetClasses,
  useGetSubjects,
  useGetChapters,
  useGetQuestions,
  useGetTopics,
} from "hooks/api/subject";

import { useAppContext } from "context/app.context";
import { useGetBoardCampus } from "hooks/api/students";

const NumberOfQuizesFilters = ({
  board,
  setBoard,
  sClasses,
  setSClasses,
  subject,
  setSubject,
}) => {

  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: campus, refetch, isLoading } = useGetBoardCampus(board);
 

  return (
    <div className="body">
      <div className="row clearfix">
        <div className="col-4 mb-4 mb-4">
          <CustomSelect
            label="Boards"
            value={board || ""}
            onChange={(e) => setBoard(e.target.value)}
            options={boards?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        <div className="col-4 mb-4">
          <CustomSelect
            label="Classes"
            value={sClasses || ""}
            onChange={(e) => setSClasses(e.target.value)}
            options={classes?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        
          <div className="col-4 mb-4">
            <CustomSelect
              label="Subjects"
              value={subject || ""}
              onChange={(e) => setSubject(e.target.value)}
              options={subjects?.data?.map((ele) => ({
                value: ele.id,
                label: ele.name,
              }))}
            />
          </div> 
      </div>
    </div>
  );
};

export default NumberOfQuizesFilters;

const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <label className="input-group-text">{label}</label>
      </div>
      <select className="custom-select" value={value} onChange={onChange}>
        <option value="">-- select an option --</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
