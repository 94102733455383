import React from "react";

// components
import PageHeader from "../../components/BreadCrumb";
import SparkleCard from "../../components/SparkleCard";
import FeedCards from "../../components/Dashboard/FeedsCard";
import BImg from "../../assets/images/Banner-stats.jpg";

// hooks
import { useGetDashboardStats } from "hooks/api/dashboard";
import PaperOverviewGrapgh from "components/Dashboard/PaperOverviewGraph";

// data
import { cardStatsData } from "Data/dashboardStats";
import { useAuthContext } from "context/auth.context";

const Dashboard = () => {
  const { user } = useAuthContext();
  const { data: stats } = useGetDashboardStats();

  const cardData = cardStatsData(stats?.data, user?.rolename);

  const loadDataCard = () => {
    const allCardData = [...cardData];
    cardData.forEach((data, i) => {
      const uData = [];
      data.sparklineData.data.forEach((d, j) => {
        uData[j] = Math.floor(Math.random() * 10) + 1;
      });
      allCardData[i].sparklineData.data = [...uData];
    });

    return allCardData;
  };

  console.log("cardData", cardData);

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "Dashboard" }]} />
      <div className="card">
        <div className="body">
          <img src={BImg} alt="" width="1080px" />
        </div>
      </div>
      <div className="row clearfix">
        {loadDataCard().map((data, i) => {
          if (!!data?.show) {
            return (
              <SparkleCard
                index={i}
                key={data.heading}
                Heading={data.heading}
                Money={data.money}
                PerText={data.perText}
                isRandomUpdate={true}
                // Data={data.sparklineData}
                mainData={data.sparklineData.data}
                chartColor={data.sparklineData.areaStyle.color}
                ContainerClass="col-lg-3 col-md-6 col-sm-6"
              />
            );
          } else {
            return <></>;
          }
        })}
      </div>
      <div className="row clearfix">
        <div className="col-lg-8 col-md-12">
          <PaperOverviewGrapgh />
        </div>
        <div className="col-lg-4 col-md-12">
          <FeedCards />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
