import React from "react";

const infoStyle = { border: "1px solid black", padding: "0.1rem 1rem " };
const PaperStudentEntryKPK = ({ data }) => {
  return (
    <div className="font-weight-bold">
      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Subject: {data?.paperMcqs[0]?.subject_name}</div>
        <div>Campus: ________________</div>
      </div>
      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          Roll No: <span className="mx-2"></span>{" "}
          <span style={infoStyle}></span>
          <span style={infoStyle}></span>
          <span style={infoStyle}></span>
          <span style={infoStyle}></span>
          <span style={infoStyle}></span>
          <span style={infoStyle}></span>
        </div>
        <div>Answer Sheet No: ________________</div>
      </div>

      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Sign. of Candidate: ________________</div>
        <div>Sign. of Invigilator: ________________</div>
      </div>
    </div>
  );
};

export default PaperStudentEntryKPK;
