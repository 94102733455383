import { defaultAdminPermissions } from "constant";

export const cardStatsData = (data, rolename) => {
  const {
    total_users = 0,
    total_active_users = 0,
    questions_count = 0,
    long_question_count = 0,
    short_question_count = 0,
    total_mcqs = 0,
    paper_generated = 0,
  } = data || {};

  return [
    {
      icon: "icon-user",
      heading: "Users",
      money: total_users,
      perText: "Total users",
      show: defaultAdminPermissions.includes(`${rolename?.toLowerCase()}`),
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 1, 5],
        areaStyle: {
          color: "#f79647",
        },
        itemStyle: {
          color: "#f79647",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-user",
      heading: "Active Users",
      money: total_active_users,
      perText: "Total active users",
      show: defaultAdminPermissions.includes(`${rolename?.toLowerCase()}`),
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 1, 5],
        areaStyle: {
          color: "#5ebc4f",
        },
        itemStyle: {
          color: "#5ebc4f",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-docs",
      heading: "All Questions",
      money: questions_count,
      perText: "Total questions listed",
      show: true,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "#604a7b",
        },
        itemStyle: {
          color: "#604a7b",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-docs",
      heading: "Long Questions",
      money: long_question_count,
      perText: "Total long questions listed",
      show: true,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "#604a7b",
        },
        itemStyle: {
          color: "#604a7b",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-docs",
      heading: "Short Questions",
      money: short_question_count,
      perText: "Total short questions listed",
      show: true,
      sparklineData: {
        type: "line",
        data: [1, 4, 2, 3, 6, 2],
        areaStyle: {
          color: "#604a7b",
        },
        itemStyle: {
          color: "#604a7b",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-docs",
      heading: "MCQS",
      money: total_mcqs,
      perText: "Total mcqs listed",
      show: true,
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "#4f81bc",
        },
        itemStyle: {
          color: "#4f81bc",
        },
        symbolSize: 1,
      },
    },
    {
      icon: "icon-docs",
      heading: "Papers",
      money: paper_generated,
      perText: "Total papers generated",
      show: defaultAdminPermissions.includes(`${rolename?.toLowerCase()}`),
      sparklineData: {
        type: "line",
        data: [1, 3, 5, 1, 4, 2],
        areaStyle: {
          color: "#bc4fa8",
        },
        itemStyle: {
          color: "#bc4fa8",
        },
        symbolSize: 1,
      },
    },
  ];
};
