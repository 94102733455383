import React from "react";

// components
import PaperHeader from "../PaperHeader";
import PaperStudentEntry from "../PaperStudentEntry";
import {
  EnglishMCQSFormat,
  MCQSFormat,
  MathMCQSFormat,
  PhysicsMCQSFormat,
} from "./MCQS";
import {
  LongEnglishQuestions,
  LongMathQuestions,
  LongPhysicsQuestions,
  LongQuestions,
  ShortQuestions,
} from "./Questions";
import PaperStudentEntryKPK from "./PaperStudentEntryKPK";
import PaperStudentEntryPunjab from "../Punjab/PaperStudentEntryPunjab";

const KpkPaperFormat = ({ data, showMcqAnswer }) => {
  const isEnglish = ["1", "11"].includes(
    `${data?.paper_details[0]?.subject_id}`
  );

  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  const isMcqExist = !!(data?.paperMcqs.length > 0);

  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );

  const isPunjab = data?.paper_details[0].board_type == "Punjab";
  const boardCheck = data?.paper_details[0]?.paper_board_type_id;
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const is10 = data?.paper_details[0]?.class_id === 2;
  return (
    <div style={{ color: "black", fontSize: "22px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />

            {(boardCheck === 1 && subjectCheck === 13) ||
            subjectCheck === 12 ||
            subjectCheck === 6 ||
            subjectCheck === 7 ? (
              <PaperStudentEntryPunjab
                boardCheck={boardCheck}
                subjectCheck={subjectCheck}
                data={data}
              />
            ) : (
              <PaperStudentEntryKPK data={data} />
            )}

            {subjectCheck === 13 || subjectCheck === 12 ? (
              <div className=" d-flex">
                <span className="font-weight-bold">Note:- </span>
                <ol>
                  <li>
                    Four circles are given with every question. Only fill the
                    circle with correct answer.{" "}
                  </li>
                  <li>Use blue or black pen to shade (fill in) the circles.</li>
                  <li>
                    More than one shaded circle for a question will be
                    considered as wrong answer.
                  </li>
                </ol>
              </div>
            ) : (
              <div className=" ">
                <span className="font-weight-bold">Note: </span>
                {data?.paper_details[0].objective_note}
              </div>
            )}

            {/* <div className=" ">
              <span className="font-weight-bold">Note:- </span>
              {data?.paper_details[0].objective_note}
            </div> */}
            {is10 && !subjectCheck === 13 && (
              <div className="mb-3">
                <span className="text-decoration-cls font-weight-bold">
                  Attempt Section-A on the same paper and return it to the
                  Examiner within the given time.{" "}
                </span>{" "}
                <br />
                No marks will be rewarded for Cutting, Erasing or Overwriting.
              </div>
            )}

            {subjectCheck === 13 ||
            subjectCheck === 12 ||
            subjectCheck === 6 ||
            subjectCheck === 7 ? (
              <div className="row clearfix font-weight-bold ">
                <div className="col-4">
                  Time Allowed: {data?.paper_details[0].objective_time}
                </div>
                <div
                  className={`${
                    !is10 && subjectCheck === 12 && "text-decoration-cls"
                  }  col-4 text-center`}
                >
                  SECTION-A
                </div>
                <div className="col-4 text-right">
                  Marks: {data?.paper_details[0].objective_marks}
                </div>
              </div>
            ) : (
              <>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className=" text-decoration-cls col-12 text-center">
                    SECTION-A
                  </div>
                </div>
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6">
                    Time: {data?.paper_details[0].objective_time}
                  </div>

                  <div className="col-6 text-right">
                    Marks: {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>
            )}

            {(is10 && !subjectCheck === 13) ||
            (is10 && isEnglish) ||
            subjectCheck === 6 ||
            subjectCheck === 7 ? (
              <div className="row mx-1">
                <div
                  style={{
                    border: "1px solid black",
                  }}
                  className="col-12 d-flex"
                >
                  <span
                    style={{
                      borderRight: "1px solid black",
                      padding: "0 1rem 0 0",
                    }}
                    className="font-weight-bold "
                  >
                    Note:
                  </span>
                  <ol>
                    <li>
                      Four circles are given with every question. Only fill the
                      circle with correct answer.
                    </li>
                    <li>
                      Use blue or black pen to shade (fill in) the circles.
                    </li>
                    <li>
                      More than one shaded circle for a question will be
                      considered as wrong answer.
                    </li>
                  </ol>
                </div>
              </div>
            ) : (
              <></>
            )}

            {(boardCheck === 1 && isEnglish) ||
            subjectCheck === 6 ||
            subjectCheck === 7 ? (
              <EnglishMCQSFormat subjectCheck={subjectCheck} data={data} />
            ) : (
              <>
                {boardCheck === 1 && subjectCheck === 13 ? (
                  <PhysicsMCQSFormat data={data} />
                ) : (
                  <>
                    {" "}
                    {boardCheck === 1 && subjectCheck === 12 ? (
                      <MathMCQSFormat data={data} />
                    ) : (
                      <MCQSFormat data={data} />
                    )}{" "}
                  </>
                )}
              </>
            )}
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          {subjectCheck === 6 || subjectCheck === 7 ? (
            <PaperStudentEntryPunjab
              boardCheck={boardCheck}
              subjectCheck={subjectCheck}
              data={data}
            />
          ) : (
            <></>
          )}
          {subjectCheck !== 6 || subjectCheck !== 7 ? (
            <></>
          ) : (
            <>
              {subjectCheck === 13 || subjectCheck === 12 ? (
                <div className="row clearfix mb-3 font-weight-bold ">
                  <div className="col-4">
                    Time Allowed: {data?.paper_details[0].subjective_time}
                  </div>
                  <div
                    className={`${
                      !is10 && subjectCheck === 12 && "text-decoration-cls"
                    }  col-4 text-center`}
                  >
                    SECTION-B
                  </div>
                  <div className="col-4 text-right">
                    Marks: {data?.section[0]?.marks}
                  </div>
                </div>
              ) : (
                <div className="row clearfix font-weight-bold mb-3">
                  <div className="col-6">
                    Time: {data?.paper_details[0].subjective_time}
                  </div>
                  {/* <div className="col-4 text-center">SUBJECTIVE</div> */}
                  <div className="col-6 text-right">
                    Total Marks: {data?.paper_details[0].subjective_marks}
                  </div>
                </div>
              )}
            </>
          )}

          {!(subjectCheck === 13 || subjectCheck === 12) && (
            <>
              {!!data?.section[0]?.text && (
                <div
                  className={`mb-3 py-2 ${
                    subjectCheck === 6 || subjectCheck === 7
                      ? ""
                      : "borderTop borderBottom"
                  }`}
                >
                  <span className="font-weight-bold">Note: </span>
                  {data?.section[0]?.text}
                </div>
              )}
            </>
          )}

          {isShortQuestionsExist &&
            !(
              subjectCheck === 13 ||
              subjectCheck === 12 ||
              subjectCheck === 6 ||
              subjectCheck === 7
            ) && (
              <div className="text-decoration-cls text-center font-weight-bold">
                {isEnglish ? "SECTION-B" : "PART-I"}
              </div>
            )}
          {subjectCheck === 6 || subjectCheck === 7 ? (
            <div className="row clearfix mb-3 font-weight-bold ">
              <div
                className={`${
                  !is10 && subjectCheck === 12 && "text-decoration-cls"
                } col-7 text-right ${
                  subjectCheck === 7 && "text-decoration-cls"
                } `}
              >
                SECTION-B
              </div>
              <div className="col-5 text-right">
                Marks: {data?.section[0]?.marks}
              </div>
            </div>
          ) : (
            <></>
          )}
          {isShortQuestionsExist && (
            <div className="text-center">
              {isEnglish && <>(Marks: {data?.section[0]?.marks})</>}
            </div>
          )}

          <ShortQuestions
            is10={is10}
            subject_id={data?.paper_details[0]?.subject_id}
            data={data?.section[0]}
            isMcqExist={isMcqExist}
            subjectCheck={subjectCheck}
          />

          {isLongQuestionsExist && (
            <>
              <div
                className={`${
                  (isEnglish || (!is10 && subjectCheck === 12)) &&
                  "text-decoration-cls"
                }  text-center font-weight-bold mt-4 ${
                  subjectCheck === 7 && "text-decoration-cls"
                }`}
              >
                {isEnglish
                  ? "SECTION C"
                  : subjectCheck === 13 ||
                    subjectCheck === 12 ||
                    subjectCheck === 6 ||
                    subjectCheck === 7
                  ? "SECTION-C"
                  : "PART-II"}
              </div>
              <div className="text-center mb-2">
                {isEnglish && <>({data?.section[1]?.marks} Marks)</>}
              </div>
            </>
          )}
          {!is10 && subjectCheck === 13 && (
            <div className="row clearfix mb-3 font-weight-bold ">
              <div className="col-12 text-right">
                Marks: {data?.section[1]?.marks}
              </div>
            </div>
          )}
          {(is10 && subjectCheck === 12) || subjectCheck === 6 ? (
            <div className="row clearfix mb-3 font-weight-bold ">
              <div className="col-12 text-right">
                Marks: {data?.section[1]?.marks}
              </div>
            </div>
          ) : (
            <></>
          )}
          {is10 && subjectCheck === 12 && !!data?.section[1]?.text && (
            <>
              <div className="mb-3">
                <span className="font-weight-bold">NOTE: </span>
                {data?.section[1]?.text}
              </div>
            </>
          )}
          {subjectCheck === 6 && !!data?.section[1]?.text && (
            <>
              <div className="mb-3">
                <span className="font-weight-bold">NOTE: </span>
                {data?.section[1]?.text}
              </div>
            </>
          )}
          {!is10 && subjectCheck === 13 && !!data?.section[1]?.text && (
            <>
              <div className="mb-3">
                <span className="font-weight-bold">NOTE: </span>
                {data?.section[1]?.text}
              </div>
            </>
          )}
          {!is10 && subjectCheck === 12 && !!data?.section[1]?.text && (
            <>
              <div className="row clearfix mb-3  ">
                <div className="col-10">{data?.section[1]?.text}</div>
                <div className="col-2 text-right font-weight-bold">
                  Marks: {data?.section[1]?.marks}
                </div>
              </div>
            </>
          )}
          {is10 && subjectCheck === 13 && !!data?.section[1]?.text && (
            <>
              <div className="row clearfix mb-3  ">
                <div className="col-10">{data?.section[1]?.text}</div>
                <div className="col-2 text-right font-weight-bold">
                  Marks: {data?.section[1]?.marks}
                </div>
              </div>
            </>
          )}

          {boardCheck === 1 && isEnglish ? (
            <LongEnglishQuestions
              is10={is10}
              data={data?.section[1]}
              startIndex={data?.section[0]?.paperShortQuestions?.length || 0}
              isMcqExist={isMcqExist}
            />
          ) : (
            <>
              {subjectCheck === 13 ||
              subjectCheck === 6 ||
              subjectCheck === 7 ? (
                <LongPhysicsQuestions
                  is10={is10}
                  subjectCheck={subjectCheck}
                  data={data?.section[1]}
                  startIndex={
                    data?.section[0]?.paperShortQuestions?.length || 0
                  }
                  isMcqExist={isMcqExist}
                />
              ) : (
                <>
                  {subjectCheck === 12 ? (
                    <LongMathQuestions
                      is10={is10}
                      data={data?.section[1]}
                      startIndex={
                        data?.section[0]?.paperShortQuestions?.length || 0
                      }
                      isMath={subjectCheck === 12}
                      isMcqExist={isMcqExist}
                    />
                  ) : (
                    <>
                      <div className="row clearfix font-weight-bold mb-3">
                        <div className="col-9">
                          Note: {data?.section[1].text}
                        </div>
                        <div className="col-3 text-right">
                          {data?.section[1].marks}
                        </div>
                      </div>
                      <LongQuestions
                        subject_id={data?.paper_details[0]?.subject_id}
                        data={data?.section[1]}
                        startIndex={
                          data?.section[0]?.paperShortQuestions?.length || 0
                        }
                        isMcqExist={isMcqExist}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            <PaperStudentEntry data={data} />

            <div className="row clearfix font-weight-bold mb-3">
              <div className="col-4">
                Time: {data?.paper_details[0].objective_time}
              </div>
              <div className="col-4 text-center">OBJECTIVE ANSWER SHEET</div>
              <div className="col-4 text-right">
                Total Marks: {data?.paper_details[0].objective_marks}
              </div>
            </div>

            <div className="mb-3">
              <span className="font-weight-bold">Note:- </span>
              {data?.paper_details[0].objective_note}
            </div>

            {isEnglish ? (
              <EnglishMCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            ) : (
              <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default KpkPaperFormat;
