import React, { useState, useEffect } from "react";
import { useParams,useHistory } from "react-router-dom";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// components
import PageHeader from "components/BreadCrumb";
import EntryFeedBackField from "components/EntryFeedBackField";

// hooks
import { useGetMCQ, useGetMCQS, useReviewMCQS } from "hooks/api/subject";

// context
import { useAuthContext } from "context/auth.context";

//
import { IMAGE_URL, dataReviewUser } from "constant";

const MCQDetail = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const history = useHistory();
  const [state, setState] = useState({});
  const [rows, setRows] = useState([]);
  const { data: mcqs } = useGetMCQS();
  const { data: mcq, refetch } = useGetMCQ(id);

  const reviewMCQ = useReviewMCQS();
  useEffect(() => {
    if (mcqs?.data) {
      setRows(mcqs?.data);
    }
  }, [mcqs]);
  const getCurrentMCQIndex = () => {
    return rows.findIndex((mcq) => mcq.id == id);
  };
  const navigateToMCQ = (newIndex) => {
    if (newIndex >= 0 && newIndex < rows.length) {
      const newMCQId = rows[newIndex].id;
      history.push(`/mcqs/${newMCQId}`);
    }
  };
  const onNext = () => {
    const currentIndex = getCurrentMCQIndex();
    if (currentIndex !== -1 && currentIndex < rows.length - 1) {
      navigateToMCQ(currentIndex + 1);
    }
  };

  const onPrevious = () => {
    const currentIndex = getCurrentMCQIndex();
    if (currentIndex !== -1 && currentIndex > 0) {
      navigateToMCQ(currentIndex - 1);
    }
  };


  const onSave = async () => {
    const body = { ...state };
    await reviewMCQ.mutateAsync({ id, body });
    refetch();
  };

  useEffect(() => {
    if (mcq?.data) {
      setState({
        status: mcq?.data[0]?.status,
        feedback: mcq?.data[0]?.feeback || "",
      });
    }
  }, [mcq]);

  const rightOption = mcq?.data[0].option_answer;

  const isMath = ["5", "12"].includes(`${mcq?.data[0].subject_id}`);
  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[{ name: "MCQS", navigate: "/mcqs" }, { name: "Detail" }]}
      />
      <div className="card">
        <div className="header">
          <h2>MCQ Detail</h2>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th scope="row">Board:</th>
                <td>{mcq?.data[0].board_name}</td>
              </tr>
              <tr>
                <th scope="row">Subject:</th>
                <td>{mcq?.data[0].subjectname}</td>
              </tr>
              <tr>
                <th scope="row">Class:</th>
                <td>{mcq?.data[0].classname}</td>
              </tr>
              <tr>
                <th scope="row">Chapter Name:</th>
                <td>{mcq?.data[0].chapter_name}</td>
              </tr>
              <tr>
                <th scope="row">Topic Name:</th>
                <td>{mcq?.data[0].topic_name}</td>
              </tr>
              <tr>
                <th scope="row">MCQ:</th>

                <td>
                  {isMath ? (
                    <MathJax math={mcq?.data[0].description} />
                  ) : (
                    parse(`${mcq?.data[0].description}`)
                  )}

                  {!!mcq?.data[0]?.file && mcq?.data[0]?.file != "" && (
                    <MCQImage image={mcq?.data[0].file} />
                  )}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: rightOption === "opt_a" && "lightGreen",
                }}
              >
                <th scope="row">Option A:</th>

                <td>
                  {isMath ? (
                    <MathJax math={mcq?.data[0].opt_a} />
                  ) : (
                    parse(`${mcq?.data[0].opt_a}`)
                  )}

                  {mcq?.data[0].opt_a_file != "" && (
                    <MCQImage image={mcq?.data[0].opt_a_file} />
                  )}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: rightOption === "opt_b" && "lightGreen",
                }}
              >
                <th scope="row">Option B:</th>

                <td>
                  {isMath ? (
                    <MathJax math={mcq?.data[0].opt_b} />
                  ) : (
                    parse(`${mcq?.data[0].opt_b}`)
                  )}

                  {mcq?.data[0].opt_b_file != "" && (
                    <MCQImage image={mcq?.data[0].opt_b_file} />
                  )}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: rightOption === "opt_c" && "lightGreen",
                }}
              >
                <th scope="row">Option C:</th>

                <td>
                  {isMath ? (
                    <MathJax math={mcq?.data[0].opt_c} />
                  ) : (
                    parse(`${mcq?.data[0].opt_c}`)
                  )}

                  {mcq?.data[0].opt_c_file != "" && (
                    <MCQImage image={mcq?.data[0].opt_c_file} />
                  )}
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: rightOption === "opt_d" && "lightGreen",
                }}
              >
                <th scope="row">Option D:</th>

                <td>
                  {isMath ? (
                    <MathJax math={mcq?.data[0].opt_d} />
                  ) : (
                    parse(`${mcq?.data[0].opt_d}`)
                  )}

                  {mcq?.data[0].opt_d_file != "" && (
                    <MCQImage image={mcq?.data[0].opt_d_file} />
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{mcq?.data[0].review_status}</td>
              </tr>
              <tr>
                <th scope="row">Feedback:</th>
                <td>{mcq?.data[0].feeback}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="body">
          <button
            type="submit"
            className="btn btn-success ml-auto"
            onClick={onPrevious}
            disabled={getCurrentMCQIndex() <= 0}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-success mx-3"
            onClick={onNext}
            disabled={getCurrentMCQIndex() >= rows.length - 1}
          >
            Next
          </button>
        </div>
        {user.rolename.toLowerCase() === dataReviewUser && (
          <EntryFeedBackField
            state={state}
            setState={setState}
            onSave={onSave}
          />
        )}
      </div>
    </div>
  );
};

export default MCQDetail;

const MCQImage = ({ image }) => {
  return (
    <img
      src={`${IMAGE_URL}/mcqs/${image}`}
      alt="image"
      style={{ maxWidth: "200px", width: "150px" }}
    />
  );
};
