import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

// packages
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

// api
import { login, bssLogin } from "api/v1/auth";

// hook
import { useToaster } from "hooks/custom/useToaster";

// context
import { useAuthContext } from "context/auth.context";

// image
import loginLogo from "../assets/logo/login.png";
import eduLogo from "../assets/images/TELoginLogo.png";
import bssLogo from "../assets/images/BSSLoginLogopng.png";

export default function Login() {
  const history = useHistory();
  const { onLogin } = useAuthContext();
  const { toasterError, toasterSuccess } = useToaster();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  const { handleSubmit, control, formState } = useForm();

  async function onSubmit(data) {
    try {
      const res = await login(data);
      whenLogin(res);
    } catch (err) {}
  }

  const googleLogin = async (response) => {
    try {
      const data = jwt_decode(response.credential);
      const res = await bssLogin({ googleId: data.jti, email: data.email });
      whenLogin(res);
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  };

  const whenLogin = (data) => {
    onLogin(data);
    history.push("/");
  };

  return (
    <div className="theme-cyan">
      <Loader isLoading={isLoading} />

      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main">
            <div className="auth-box">
              <div
                style={{
                  display: "flex",
                  marginTop: "40px",
                  marginBottom: "70px",
                  justifyContent: "space-around",
                }}
              >
                <img src={bssLogo} alt="logo" width="100px" height="100px"/>
                <img src={eduLogo} alt="logo" width="100px" height="100px"/>
              </div>

              <div>
                <img
                  src={loginLogo}
                  alt="logo"
                  style={{
                    height: "70px",
                    width: "210px",
                    marginBottom: "70px",
                  }}
                />
              </div>

              <div>
                <div className="header">
                  <p
                    className="lead"
                    style={{ fontWeight: "600", marginBottom: "20px" }}
                  >
                    Login your account
                  </p>
                </div>
                <div className="body">
                  <div className="form-auth-small">
                    <LoginInput
                      control={control}
                      error={formState?.errors?.email}
                      item={{
                        type: "text",
                        name: "email",
                        label: "Email",
                        placeholder: "Enter email",
                      }}
                    />
                    <LoginInput
                      control={control}
                      error={formState?.errors?.password}
                      item={{
                        type: "password",
                        name: "password",
                        label: "Password",
                        placeholder: "Enter password",
                      }}
                    />
                    <div
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      onClick={handleSubmit(onSubmit)}
                      style={{ backgroundColor: "#00A88E" }}
                    >
                      Login
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <GoogleLogin
                        onSuccess={googleLogin}
                        onError={() => console.log("Login Failed")}
                        useOneTap
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Loader = ({ isLoading }) => {
  return (
    <div
      className="page-loader-wrapper"
      style={{ display: isLoading ? "block" : "none" }}
    >
      <div className="loader">
        <div className="m-t-30">
          <img
            src={require("../assets/images/logo-icon.svg")}
            width="48"
            height="48"
            alt="Lucid"
          />
        </div>
        <p>Please wait...</p>
      </div>
    </div>
  );
};

const LoginInput = ({ item, error, control }) => {
  return (
    <Controller
      control={control}
      defaultValue={""}
      name={item.name}
      rules={{
        required: {
          value: true,
          message: "This field is required!",
        },
      }}
      render={({ field }) => (
        <div className="form-group">
          <label className="control-label sr-only">{item.label}:</label>
          <input
            {...field}
            className="form-control"
            type={item.type || "text"}
            placeholder={item.placeholder}
          />
          {error?.message && (
            <ul className="parsley-errors-list filled">
              <li className="parsley-required">{error?.message}</li>
            </ul>
          )}
        </div>
      )}
    />
  );
};
