import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

// util
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";
import { numberToAlphabet } from "utils/Helper";

const alphabets = ["a", "b", "c", "d", "e", "f", "g", "h"];

export const ShortQuestions = ({ data, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperShortQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>
                {subject_id === 16 || 18 ? (
                  <>{index + startingQuestionNumber}. </>
                ) : (
                  <>Q.{index + startingQuestionNumber} </>
                )}{" "}
              </span>
              {ele.text}
            </div>
            <div className="col-2 text-right">{ele.marks}</div>
          </div>
          {ele?.sq_question_ids?.map((ques, index) => (
            <>
              <p
                className="col-12"
                style={{
                  display: "flex",
                  alignItems: isMath ? "center" : "flex-start",
                  margin: 0,
                }}
              >
                <span style={{ minWidth: "35px" }}>{`(${convertToRoman(
                  index + 1
                )})`}</span>
                <span>
                  {isMath ? (
                    <MathJax math={`${ques.question}`} />
                  ) : (
                    parse(`${ques.question}`)
                  )}
                </span>
              </p>
              {ques.file && (
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "-0.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    src={`${IMAGE_URL}/${ques.file}`}
                    alt="image"
                    style={{ maxWidth: "500px" }}
                  />
                </p>
              )}
            </>
          ))}
        </div>
      ))}
    </div>
  );
};

export const LongQuestions = ({ data, startIndex, subject_id, isMcqExist }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);
  const isChemistry = ["6"].includes(`${subject_id}`);
  const startingQuestionNumber = isMcqExist ? 2 : 1;
  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-3">
          <div className="row clearfix font-weight-bold mb-2">
            <div className="col-10">
              <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
              {ele.text}
            </div>
            {/* <div className="col-2 text-right">{ele.marks}</div> */}
          </div>

          {ele.ui_style == "math_long_q9_include_or_and_single_marks" &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${
                    alphabets[QueIndex + 0]
                  })`}</span>
                  <span style={{ width: "100%" }}>
                    <MathJax math={`${ques.question}`} />
                  </span>

                  {QueIndex == 0 && (
                    <div
                      style={{ maxWidth: "100%" }}
                      className="text-right font-weight-bold"
                    >
                      (0{ques.marks})
                    </div>
                  )}
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}

                {ele?.question_ids.length > 1 && QueIndex == 0 && (
                  <div
                    className="text-center font-weight-bold mt-1 mb-1"
                    style={{ width: "100%" }}
                  >
                    OR
                  </div>
                )}
              </>
            ))}

          {!ele.ui_style &&
            ele?.question_ids?.map((ques, QueIndex) => (
              <>
                <p
                  className="col-12"
                  style={{
                    display: "flex",
                    alignItems: isMath ? "center" : "flex-start",
                    margin: 0,
                  }}
                >
                  <span style={{ marginRight: "5px", fontWeight: "bold" }}>{`(${
                    alphabets[QueIndex + 0]
                  })`}</span>
                  <span style={{ width: "100%" }}>
                    {isMath ? (
                      <MathJax math={`${ques.question}`} />
                    ) : (
                      parse(`${ques.question}`)
                    )}
                  </span>

                  <div
                    style={{ maxWidth: "100%" }}
                    className="text-right font-weight-bold"
                  >
                    (0{ques.marks})
                  </div>
                </p>

                {ques.file && (
                  <p
                    className="col-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "-0.5rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <img
                      src={`${IMAGE_URL}/${ques.file}`}
                      alt="image"
                      style={{ maxWidth: "500px" }}
                    />
                  </p>
                )}
              </>
            ))}
        </div>
      ))}
    </div>
  );
};

export const LongEnglishQuestions = ({ data, startIndex, isMcqExist }) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {!!ele.text && (
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>Q.{index + startIndex + startingQuestionNumber} </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          )}

          {ele.ui_style == "rtl_row_2_bullet" && (
            <>
              <div className="row rtl-list text-right">
                {ele?.question_ids?.map((ques, queIndex) => (
                  <BulletSubQuestions
                    col="col-6"
                    number={`(${convertToRoman(queIndex + 1)})`}
                    text={parse(`${ques.question}`)}
                  />
                ))}
              </div>

              {data?.paperLongQuestions[index + 1]?.question_ids[0]
                ?.chapter_id == 426 && (
                <div className="col-12 font-weight-bold">
                  Exclusively for candidates whose Medium of Examination is
                  English (Question—9 Alternative to Urdu Translation)
                </div>
              )}
            </>
          )}

          {ele.ui_style == "single_line_bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-3"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {ele.ui_style == "punjab_10_english_essay_writing_and_para" && (
            <Punjab10EssayWriting data={ele} />
          )}

          {ele.ui_style == "punjab_10_translation_with_ten_sentences" && (
            <Punjab10ParaTranslation data={ele} />
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `Q${index + startIndex + startingQuestionNumber}.`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="text-right font-weight-bold"
                      >
                        {ele.marks}
                      </div>
                    )}
                  </div>

                  {queIndex + 1 < ele?.question_ids?.length && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export const LongPakStudiesQuestions = ({
  data,
  startIndex,
  isMcqExist,
  subjectCheck,
}) => {
  const startingQuestionNumber = isMcqExist ? 2 : 1;

  return (
    <div>
      {data?.paperLongQuestions?.map((ele, index) => (
        <div className="mb-5">
          {!!ele.text && (
            <div className="row clearfix font-weight-bold mb-2">
              <div className="col-10">
                <span>{index + startIndex + startingQuestionNumber}. </span>{" "}
                {ele.text}
              </div>
              <div className="col-2 text-right">{ele.marks}</div>
            </div>
          )}

          {ele.ui_style == "rtl_row_2_bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`(${numberToAlphabet(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}
          {ele.ui_style == "bullet" && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <BulletSubQuestions
                  col="col-12"
                  number={`(${convertToRoman(queIndex + 1)})`}
                  text={parse(`${ques.question}`)}
                />
              ))}
            </div>
          )}

          {!ele.ui_style && (
            <div className="row">
              {ele?.question_ids?.map((ques, queIndex) => (
                <div className="col-12">
                  <div style={{ display: "flex", width: "100%" }}>
                    <span style={{ minWidth: "35px", fontWeight: "bold" }}>
                      {queIndex == 0 && !ele.text
                        ? `${index + startIndex + startingQuestionNumber}.`
                        : ""}
                    </span>

                    <span
                      className="text-margin-none"
                      style={{ width: "100%" }}
                    >
                      {parse(`${ques.question}`)}
                    </span>

                    {queIndex == 0 && !ele.text && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="text-right font-weight-bold"
                      >
                        {subjectCheck === 18 ? <></> : ele.marks}
                      </div>
                    )}
                  </div>

                  {queIndex + 1 < ele?.question_ids?.length && (
                    <div
                      className="text-center font-weight-bold mt-1 mb-1"
                      style={{ width: "100%" }}
                    >
                      OR
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Punjab10EssayWriting = ({ data }) => {
  const essayWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 446
  );
  const paragraphWriting = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 446
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ minWidth: "35px" }} />
      <div className="row">
        {essayWriting.map((ques, queIndex) => (
          <BulletSubQuestions
            col="col-4"
            number={`(${convertToRoman(queIndex + 1)})`}
            text={parse(`${ques.question}`)}
          />
        ))}

        {paragraphWriting.length > 0 && (
          <>
            <div className="col-12 text-center font-weight-bold mt-1 mb-1">
              OR
            </div>

            <div
              className="col-12 font-weight-bold mb-1"
              style={{ marginLeft: "-10px" }}
            >
              Write a paragraph of 100-150 words on any ONE of the following
              topics:
            </div>

            {paragraphWriting.map((ques, index) => (
              <BulletSubQuestions
                col="col-4"
                number={`(${convertToRoman(index + 1)})`}
                text={parse(`${ques.question}`)}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const Punjab10ParaTranslation = ({ data }) => {
  const paraTranslation = data?.question_ids?.filter(
    (ele) => ele.chapter_id == 448
  );
  const tenSentences = data?.question_ids?.filter(
    (ele) => ele.chapter_id != 448
  );
  return (
    <>
      <div className="row rtl-list text-right">
        {paraTranslation?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>

      {tenSentences.length > 0 && (
        <div
          className="text-center font-weight-bold mt-1 mb-1"
          style={{ width: "100%" }}
        >
          OR
        </div>
      )}

      <div className="row">
        {tenSentences?.map((ques, queIndex) => (
          <div className="col-12">{parse(`${ques.question}`)}</div>
        ))}
      </div>
    </>
  );
};

const BulletSubQuestions = ({ col, number, text }) => {
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ minWidth: "35px", fontWeight: "500" }}>{number}</span>
      <span>{text}</span>
    </p>
  );
};
