import client from "../api-config";

export function getTopStudents(instituteType, board, sClasses, campuses) {
  return client.get(
    `/api/v2/admin/top-students?instituteType=${instituteType}&boardType=${
      board ? board : ""
    }&classType=${sClasses ? sClasses : ""}&campusType=${
      campuses ? campuses : ""
    }`
  );
}

export function getStudentQuizesList(id, sClasses, subject_id, sub_quiz_num) {
  return client.get(
    `/api/v2/admin/quiz/student/${id}/${sClasses}?subject_id=${
      subject_id ? subject_id : ""
    }&sub_quiz_num=${sub_quiz_num ? sub_quiz_num : ""}`
  );
}

export function getNumberofQuizzes(board, sClasses, subject) {
  return client.get(
    `/api/v2/subject-quiz-num?board_id=${board ? board : ""}&class_id=${
      sClasses ? sClasses : ""
    }&subject_id=${subject ? subject : ""}`
  );
}

export function getQuizChapters(board_id, class_id, subject_id) {
  return client.get(
    `/api/v2/quiz-chapters?board_id=${board_id ? board_id : ""}&class_id=${
      class_id ? class_id : ""
    }&subject_id=${subject_id ? subject_id : ""}`
  );
}

export function getAllCampuses(boardId) {
  return client.get(`/api/v2/allcampus/${boardId}`);
}

export function getSingleStudent(id) {
  return client.get(`/api/v2/admin/single/students/${id}`);
}

export function getSingleNumberOfQuiz(id) {
  return client.get(`/api/v2/subject-quiz-num/single/${id}`);
}

export function getSingleQuizChapter(id) {
  return client.get(`/api/v2/quiz-chapters/single/${id}`);
}

export function getAllSessions() {
  return client.get(`/api/v2/allsessions`);
}

export function deleteStudent(id) {
  return client.delete(`/api/v2/admin/delete/students/${id}`);
}

export function deleteNumberOfQuiz(id) {
  return client.delete(`/api/v2/subject-quiz-num/single/${id}`);
}

export function deleteQuizChapters(id) {
  return client.delete(`/api/v2/quiz-chapters/single/${id}`);
}

export function deleteQuiz(id) {
  return client.delete(`/api/v2/admin/delete/quiz/${id}`);
}

export function createStudet(body) {
  return client.post("/api/v2/admin/add/students", body);
}

export function createNumberOfQuiz(body) {
  return client.post("/api/v2/subject-quiz-num", body);
}

export function createQuizChapters(body) {
  return client.post("/api/v2/quiz-chapters", body);
}

export function updateStudent(data) {
  return client.put(`/api/v2/admin/edit/students/${data.id}`, data.body);
}

export function updateNumberOfQuiz(data) {
  return client.put(`/api/v2/subject-quiz-num/single/${data.id}`, data.body);
}

export function updateQuizChapters(data) {
  return client.put(`/api/v2/quiz-chapters/single/${data.id}`, data.body);
}
