import React, { useState } from "react";
import PageHeader from "components/BreadCrumb";

// apis
import { askQuestion } from "api/v1/feedback";

// hook
import { useToaster } from "hooks/custom/useToaster";

const Feedback = () => {
  return (
    <div className="col-lg-8 col-md-12">
      <PageHeader Breadcrumb={[{ name: "Feedback Ask a Question" }]} />
      <div className="card">
        <div className="body">
          <ul className="nav nav-tabs" role="tablist">
            <li className={`nav-item mr-1 active`} role="presentation">
              <a className="nav-link" data-toggle="tab">
                Ask a Question
              </a>
            </li>
          </ul>
          <div className="tab-content" style={{ padding: "40px 20px" }}>
            <AskQuestion />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const AskQuestion = () => {
  const [areaInput, setAreaInput] = useState("");
  const [submeet, setSubmeet] = useState(false);
  const { toasterError, toasterSuccess } = useToaster();

  const createAskQuestion = async () => {
    if (areaInput == "") {
      return;
    }

    try {
      await askQuestion({
        question: areaInput,
      });
      toasterSuccess(`Question submitted`);
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  };

  return (
    <div id="bacicTabpan-3" className={`tab-pane show active`}>
      <h6>If you have any question, kindly write below:</h6>
      <div className="form-group">
        <textarea
          className={`form-control ${
            areaInput === "" && submeet && "parsley-error"
          }`}
          value={areaInput}
          onChange={(e) => setAreaInput(e.target.value)}
        />
        {areaInput === "" && submeet ? (
          <ul className="parsley-errors-list filled" id="parsley-id-29">
            <li className="parsley-required">This value is required.</li>
          </ul>
        ) : null}
      </div>
      <button
        className="btn btn-primary"
        onClick={() => {
          setSubmeet(true);
          createAskQuestion();
        }}
      >
        Submit
      </button>
    </div>
  );
};
