import React, { useState } from "react";

const ChangeThemeMode = () => {
  const [darkMode, setDarkMode] = useState(
    document.body.classList.contains("full-dark") ? true : false
  );

  const handleThemeMode = (mode) => {
    if (mode === "dark") {
      setDarkMode(true);
      document.body.classList.add("full-dark");
    } else {
      setDarkMode(false);
      document.body.classList.remove("full-dark");
    }
  };

  return (
    <>
      <h6>Choose Mode</h6>
      <ul className="choose-skin list-unstyled">
        <li
          data-theme="white"
          className={darkMode ? "" : "active"}
          style={{ cursor: "pointer", maxWidth: "max-content" }}
          onClick={() => handleThemeMode("light")}
        >
          <div className="white"></div>
          <span>Light</span>
        </li>
        <li
          data-theme="black"
          className={darkMode ? "active" : ""}
          style={{ cursor: "pointer", maxWidth: "max-content" }}
          onClick={() => handleThemeMode("dark")}
        >
          <div className="black"></div>
          <span>Dark</span>
        </li>
      </ul>
    </>
  );
};

const Settings = () => {
  return (
    <div className={"tab-pane p-l-15 p-r-15 show active"}>
      <ChangeThemeMode />
      <hr />
    </div>
  );
};

export default Settings;
