import client from "../api-config";

export function getPaperTypes() {
  return client.get(`/api/paperTypes`);
}

export function getPaperSessions() {
  return client.get(`/api/sessions`);
}

export function createPaper(body) {
  return client.post("/api/managerpaper/add", body);
}

export function createPunjabPaper(body) {
  return client.post("/api/managerpaperpunjab/add", body);
}

export function paperReports(body) {
  return client.post("/api/papers/report", body);
}

export function getPapers() {
  return client.get("/api/papers");
}

export function getMyPapers() {
  return client.get("/api/papersInbox");
}

export function getPaper(id) {
  return client.get(`/api/papers/${id}`);
}

export function getPunjabPaper(id) {
  return client.get(`/api/paperPunjab/${id}`);
}

export function deletePaper(id) {
  return client.delete(`api/papers/${id}`);
}
