import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// packages
import CustomPagination from "components/CustomPagination ";
import TableFilters from "components/TableFilters";

// components
import PageHeader from "components/BreadCrumb";

// hooks
import { useTable } from "hooks/custom/useTable";

// context
import { useAuthContext } from "context/auth.context";

//
import {
  dataReviewUser,
  defaultAdminPermissions,
  defaultEntryPermissions,
} from "constant";
import { CSVLink } from "react-csv";
import { useDeleteQuizChapters, useGetQuizChapters } from "hooks/api/students";
import NumberOfQuizesFilters from "components/TableFilters/NUmberOfQuizesFilters";

const QuizChapters = ({
  board_id,
  class_id,
  subject_id,
  setExistingQuizNumbers,
  setEditId,
  editId,
  addUpdate,
  setAddUpdate,
}) => {
  const history = useHistory();
  const { user } = useAuthContext();

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const {
    data: numberOfQuiz,
    refetch,
    isLoading,
  } = useGetQuizChapters(board_id, class_id, subject_id);

  useEffect(() => {
    if (board_id) {
      refetch();
    }
    if (class_id) {
      refetch();
    }
    if (subject_id) {
      refetch();
    }
  }, [board_id, class_id, subject_id, refetch]);

  const deleteQuizChapters = useDeleteQuizChapters(refetch);

  const formatDataForCSV = () => {
    return (
      numberOfQuiz?.data?.map((row) => ({
        "Quiz No.": row.quiz_number,
        Class: row.class_name,
        Board: row.board_name,
        Subjects: row.subject_name,
        Chapters: row.chapters.map((chapter) => chapter.name).join(", "),
      })) || []
    );
  };

  useEffect(() => {
    if (numberOfQuiz?.data && numberOfQuiz.data.length > 0) {
      const quizNumbers = numberOfQuiz.data.map((row) => row.quiz_number);
      setExistingQuizNumbers(quizNumbers);
    }
  }, [numberOfQuiz, setExistingQuizNumbers]);

  return (
    <div className="container-fluid">
      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Quizzes Chapters</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink
              filename={"Quiz Chapters.csv"}
              data={formatDataForCSV()}
              asyncOnClick={true}
              onClick={(event, done) => {
                if (!numberOfQuiz?.data?.length) {
                  alert("No data to export");
                  done(false);
                } else {
                  done();
                }
              }}
            >
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export Quiz Chapters</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>

            {defaultEntryPermissions.includes(user.rolename.toLowerCase()) &&
              !addUpdate && (
                <button
                  type="button"
                  className="btn btn-success"
                  title="Add Quiz Chapters"
                  onClick={() => {
                    setAddUpdate(true);
                    setEditId("");
                  }}
                >
                  <span className="sr-only">Create Quiz Chapters</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Add Quiz Chapters</span>
                </button>
              )}
          </div>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Quiz No.</th>
                  <th>Class</th>
                  <th>Board</th>
                  <th>Subjects</th>
                  <th>Chapters</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {numberOfQuiz?.data?.length > 0 ? (
                      numberOfQuiz?.data?.map((row) => (
                        <tr key={row.id}>
                          <th scope="row">{row.quiz_number}</th>
                          <th scope="row">{row.class_name}</th>
                          <td>{row.board_name}</td>
                          <td>{row.subject_name}</td>
                          <td>
                            {row.chapters
                              .map((chapter) => chapter.name)
                              .join(", ")}
                          </td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {defaultEntryPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  title="Edit"
                                  onClick={() => {
                                    setAddUpdate(false);
                                    setEditId(row.id);
                                  }}
                                >
                                  <span className="sr-only">Edit</span>
                                  <i className="fa fa-edit"></i>
                                </button>
                              )}

                              {defaultAdminPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-1"
                                  title="Delete"
                                  onClick={() =>
                                    deleteQuizChapters.mutate(row.id)
                                  }
                                >
                                  <span className="sr-only">Delete</span>
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={numberOfQuiz?.data?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default QuizChapters;
