// third party
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  createNumberOfQuiz,
  createQuizChapters,
  createStudet,
  deleteNumberOfQuiz,
  deleteQuiz,
  deleteQuizChapters,
  deleteStudent,
  getAllCampuses,
  getAllSessions,
  getNumberofQuizzes,
  getQuizChapters,
  getSingleNumberOfQuiz,
  getSingleQuizChapter,
  getSingleStudent,
  getStudentQuizesList,
  getTopStudents,
  updateNumberOfQuiz,
  updateQuizChapters,
  updateStudent,
} from "api/v1/students";

// hooks
import { getBoards } from "api/v1/subject";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetTopStudents = (instituteType, board, sClasses, campuses) => {
  return useQuery({
    queryKey: ["students"],
    queryFn: () => getTopStudents(instituteType, board, sClasses, campuses),
    refetchOnWindowFocus: false,
  });
};

export const useGetNumberOfQuizzes = (board, sClasses, subject) => {
  return useQuery({
    queryKey: ["numberOfQuiz"],
    queryFn: () => getNumberofQuizzes(board, sClasses, subject),
    refetchOnWindowFocus: false,
  });
};

export const useGetQuizChapters = (board_id, class_id, subject_id) => {
  return useQuery({
    queryKey: ["quizChapters"],
    queryFn: () => getQuizChapters(board_id, class_id, subject_id),
    
    refetchOnWindowFocus: false,
  });
};

export const useGetStudentQuizesList = (id, sClasses, subject_id, quizType) => {
  return useQuery({
    queryKey: ["quizzes"],
    queryFn: () => getStudentQuizesList(id, sClasses, subject_id, quizType),
    refetchOnWindowFocus: false,
  });
};

export const useGetBoardCampus = (boardId) => {
  return useQuery({
    queryKey: ["campus"],
    queryFn: () => getAllCampuses(boardId),
    refetchOnWindowFocus: false,
  });
};

export const useGetStudent = (id) => {
  return useQuery({
    queryKey: ["student"],
    queryFn: () => getSingleStudent(id),
    refetchOnWindowFocus: false,
  });
};

export const useGetNumberOfQuiz = (id) => {
  return useQuery({
    queryKey: ["numberOfQuiz"],
    queryFn: () => getSingleNumberOfQuiz(id),
    refetchOnWindowFocus: false,
  });
};

export const useGetSingleQuizChapter = (id) => {
  return useQuery({
    queryKey: ["quizChapter"],
    queryFn: () => getSingleQuizChapter(id),
    refetchOnWindowFocus: false,
  });
};

export const useGetSessions = () => {
  return useQuery({
    queryKey: ["sessions"],
    queryFn: () => getAllSessions(),
    refetchOnWindowFocus: false,
  });
};

export const useDeleteStudent = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteStudent,
    onSuccess: () => {
      refetch();
      toasterSuccess("Student Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteNumberOfQuiz = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteNumberOfQuiz,
    onSuccess: () => {
      refetch();
      toasterSuccess("Number of Quizzes Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteQuizChapters = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteQuizChapters,
    onSuccess: () => {
      refetch();
      toasterSuccess("Quiz Chapters Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useDeleteQuiz = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteQuiz,
    onSuccess: () => {
      refetch();
      toasterSuccess("Quiz Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useCreateStudent = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createStudet,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useCreateNumberOfQuiz = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createNumberOfQuiz,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useCreateQuizChapters = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: createQuizChapters,
    onSuccess: () => toasterSuccess("Created!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateStudent = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateStudent,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateNumberOfQuiz = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateNumberOfQuiz,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};

export const useUpdateQuizChapters = () => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: updateQuizChapters,
    onSuccess: () => toasterSuccess("Updated!"),
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
