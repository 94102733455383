import React, { useEffect, useRef } from "react";

const ImageFile = ({ image, onChange }) => {
  const imgRef = useRef(null);

  useEffect(() => {
    if (!image?.file) {
      imgRef.current.value = "";
    }
  }, [image]);
  return (
    <div className="input-group">
      <input
        className="input-file-button"
        type="file"
        ref={imgRef}
        accept="image/*"
        onChange={onChange}
      />
      <label className="input-file">Choose file</label>
    </div>
  );
};

export default ImageFile;
