import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";
// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useDeletePaper, useGetPapers } from "hooks/api/paper";

// context
import { useAuthContext } from "context/auth.context";

// assets
import bssMcqResSheet from "../../../assets/pdf/bss-mcq.pdf";
import eduMcqResSheet from "../../../assets/pdf/edu-mcq.pdf";
import { defaultAdminPermissions } from "constant";

const Papers = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const isBSSUser = user?.user_group_id == 2;

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: papers, refetch } = useGetPapers();
  const deletePaper = useDeletePaper(refetch);

  useEffect(() => {
    if (papers?.data) {
      setLoading(false);
      setRows(papers?.data);
    }
  }, [papers?.data]);

  const handleDownload = () => {
    fetch(isBSSUser ? bssMcqResSheet : eduMcqResSheet)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = isBSSUser
          ? "MCQs Response Sheet Template - BSS"
          : "MCQs Response Sheet Template - TE";
        a.click();

        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF:", error);
      });
  };

  return (
    <div className="container-fluid">
      <PageHeader HeaderText="Papers" Breadcrumb={[{ name: "Papers" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>All Paper</h2>

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink filename={"all-papers.csv"} data={rows}>
              {" "}
              <button type="button" className="btn btn-primary">
                All Paper Sheet
              </button>
            </CSVLink>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleDownload}
            >
              MCQ's Response Sheet
            </button>
            <Link to="/papers/create">
              <button
                type="button"
                className="btn btn-success"
                title="Generate Chapter"
              >
                <span className="sr-only">Generate Paper</span>
                <i className="fa fa-plus mr-1"></i> <span>Generate Paper</span>
              </button>
            </Link>
          </div>
        </div>

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Paper #</th>
                  <th>BOARD</th>
                  <th>Subject</th>
                  <th>CLASS</th>
                  <th>NAME</th>
                  <th>Type</th>
                  <th>Paper Date</th>
                  <th>Created At</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      rows
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <tr key={row?.id}>
                            <td>{row.id} </td>
                            <td>{row.board_name}</td>
                            <td>{row.subject_name}</td>
                            <td>{row.class_name}</td>
                            <td>{row.paper_title}</td>
                            <td>{row.type_name}</td>
                            <td>{new Date(row.paper_date).toDateString()}</td>
                            <td>{new Date(row.created_at).toDateString()}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary mr-1"
                                  title="View"
                                  onClick={() =>
                                    history.push(`/papers/${row.id}`)
                                  }
                                >
                                  <span className="sr-only">View</span>
                                  <i className="fa fa-eye"></i>
                                </button>

                                {defaultAdminPermissions.includes(
                                  user.rolename.toLowerCase()
                                ) && (
                                  <button
                                    type="button"
                                    className="btn btn-danger ml-1"
                                    title="Delete"
                                    onClick={() => deletePaper.mutate(row.id)}
                                  >
                                    <span className="sr-only">Delete</span>
                                    <i className="fa fa-trash-o"></i>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Papers;
