import client from "../api-config";

export function complaint(body) {
  return client.post("/api/complaint/add", body);
}

export function rateReview(body) {
  return client.post("/api/ratereview/add", body);
}

export function askQuestion(body) {
  return client.post("/api/askQuestion/add", body);
}

export function getComplaints() {
  return client.get("/api/complaints");
}

export function getRateReviews() {
  return client.get("/api/ratereview");
}

export function getAskQuestions() {
  return client.get("/api/askQuestion");
}

export function updateComplaintStatus(data) {
  return client.put(`api/complaints/status/${data.id}`, data.body);
}

export function updateAskQuestionRemarks(data) {
  return client.put(`api/askQuestion/remarks/${data.id}`, data.body);
}
