import client from "../api-config";

export function getUserGroups() {
  return client.get("/api/usergroups");
}

export function getRegions() {
  return client.get("/api/regions");
}

export function createNotification(body) {
  return client.post("/api/notification/add", body);
}

export function getNotifications() {
  return client.get("/api/notification");
}
