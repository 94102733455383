import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import parse from "html-react-parser";

// components
import PageHeader from "components/BreadCrumb";
import CustomPagination from "components/CustomPagination ";

// hooks
import { useTable } from "hooks/custom/useTable";
import { useGetFeedbacks } from "hooks/api/subject";
import { CSVLink } from "react-csv";

const MyFeedBacks = () => {
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const { data: feedbacks } = useGetFeedbacks();

  useEffect(() => {
    if (feedbacks?.data) {
      const mcqFeedback = feedbacks?.data?.mcq_feedback
        ?.map((ele) => ({
          id: ele.id,
          type: "mcq",
          value: ele.description,
          feeback: ele.feeback,
        }))
        .filter(({ feeback }) => !!feeback);
      const questionFeedback = feedbacks?.data?.questions_feedback
        ?.map((ele) => ({
          id: ele.id,
          type: "question",
          value: ele.question,
          feeback: ele.feeback,
        }))
        .filter(({ feeback }) => !!feeback);
      setRows([...mcqFeedback, ...questionFeedback]);
      setLoading(false);
    }
  }, [feedbacks?.data]);

  return (
    <div className="container-fluid">
      <PageHeader Breadcrumb={[{ name: "My Feedbacks" }]} />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h2>My Feedbacks</h2>
            <small>
              All feedbacks posted by data reviewer will be displayed here:
            </small>
          </div>

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink filename={"all-feedbacks.csv"} data={rows}>
              {" "}
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export Feedbacks</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>
          </div>
        </div>

        <div className="body table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>NAME</th>
                <th>TYPE</th>
                <th>Feedback</th>
                <th style={{ textAlign: "end" }}>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      textAlign: "center",
                      height: "30vh",
                      width: "100%",
                    }}
                    className="visible-lg"
                  >
                    <i className="fa fa-spinner fa-4x fa-spin"></i>
                  </td>
                  <td></td>
                </tr>
              ) : (
                <>
                  {rows?.length > 0 ? (
                    rows
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row) => (
                        <tr key={row.id}>
                          <th>{parse(`${row.value}`)}</th>
                          <td>{row?.type}</td>
                          <td>{row?.feeback}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-primary mr-1"
                              title="View"
                              onClick={() =>
                                row.type === "question"
                                  ? history.push(`/questions/${row.id}`)
                                  : history.push(`/mcqs/${row.id}`)
                              }
                            >
                              <span className="sr-only">View</span>
                              <i className="fa fa-eye"></i>
                            </button>

                            <button
                              type="button"
                              className="btn btn-warning mr-1"
                              title="Edit"
                              onClick={() =>
                                row.type === "question"
                                  ? history.push(`/questions/edit/${row.id}`)
                                  : history.push(`/mcqs/edit/${row.id}`)
                              }
                            >
                              <span className="sr-only">Edit</span>
                              <i className="fa fa-edit"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td
                        style={{
                          textAlign: "center",
                          height: "30vh",
                          fontSize: "1.5rem",
                          width: "100%",
                        }}
                      >
                        Oops! No data found
                      </td>
                      <td></td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={rows.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default MyFeedBacks;
