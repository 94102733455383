import client from "../api-config";

export function getRoles() {
  return client.get("/api/roles");
}

// Users
export function createUser(body) {
  return client.post("/api/user/add", body);
}

export function getUser(id) {
  return client.get(`/api/user/${id}`);
}

export function getUsers() {
  return client.get("/api/users");
}

export function updateUser(data) {
  return client.put(`/api/users/${data.id}`, { ...data.body });
}

export function deleteUser(id) {
  return client.delete(`api/users/${id}`);
}
