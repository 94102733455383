import React from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import {
  useCreateUser,
  useGetRoles,
  useGetUser,
  useUpdateUser,
} from "hooks/api/admin";
import { useGetSchools } from "hooks/api/shared";
import { useGetCampuses } from "hooks/api/campus";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateUser = () => {
  const { id } = useParams();

  const { data: user, refetch } = useGetUser(id);
  const { data: roles } = useGetRoles();
  // const { data: schools } = useGetSchools();
  const { data: campuses } = useGetCampuses();

  const createUser = useCreateUser();
  const updateUser = useUpdateUser();

  const { handleSubmit, reset, control, formState, setValue } = useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data };

      id
        ? await updateUser.mutateAsync({
            id,
            body: { ...body, user_group_id: user?.data?.[0]?.user_group_id },
          })
        : await createUser.mutateAsync(body);

      reset();
      refetch();
    } catch (err) {}
  }

  React.useEffect(() => {
    if (user?.data?.[0]) {
      let values = _.pick(user?.data?.[0], [
        "name",
        "email",
        "password",
        "roleid",
        "user_campus_id",
        // "user_group_id",
      ]);

      values = {
        ...values,
        role: values.roleid,
        campusId: values.user_campus_id,
      };

      delete values.roleid;
      delete values.user_campus_id;

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
    }
  }, [user]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create User"
        Breadcrumb={[
          { name: "Users", navigate: "/users" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} User</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Name",
                  placeholder: "Enter Name",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.email}
                item={{
                  name: "email",
                  label: "Email",
                  placeholder: "Enter Email",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.password}
                item={{
                  name: "password",
                  label: "Password",
                  placeholder: "Enter Password",
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.role}
                item={{
                  name: "role",
                  label: "Role",
                  options: roles?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.campusId}
                item={{
                  name: "campusId",
                  label: "Campus",
                  options: campuses?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              {/* <FormSelect
                control={control}
                error={formState?.errors?.user_group_id}
                item={{
                  name: "user_group_id",
                  label: "Schools",
                  options: schools?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              /> */}
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;

const chapterNo = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
];
