import React from "react";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";

//
import { convertToRoman } from "utils/convertToRoman";
import { IMAGE_URL } from "constant";

const MCQSCol = ({ col, number, text, image, isRightOption, subject_id }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);

  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  return (
    <p
      className={col}
      style={{
        display: "flex",
        alignItems: isMath ? "center" : "flex-start",
        margin: 0,
      }}
    >
      <span style={{ marginRight: "5px", fontWeight: "500", ...active }}>
        {number}
      </span>
      <span>
        <span>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </p>
  );
};
const MCQSTab = ({ col, number, text, image, isRightOption, subject_id }) => {
  const isMath = ["5", "12"].includes(`${subject_id}`);

  const active = isRightOption && {
    backgroundColor: "lightgreen",
    border: "1px solid green",
    borderRadius: "1px",
    color: "#fff",
    padding: "0 2px",
  };
  return (
    <div className="d-flex mx-2">
      <span style={{ marginRight: "5px", fontWeight: "500", ...active }}>
        {number}
      </span>

      <span>
        <span>{text}</span>

        {!!image && image != "" && (
          <span>
            <img
              src={`${IMAGE_URL}/mcqs/${image}`}
              alt="image"
              style={{ maxWidth: "100%" }}
            />
          </span>
        )}
      </span>
    </div>
  );
};

export const MCQSFormat = ({ data, showMcqAnswer }) => {
  const isMath = ["5", "12"].includes(`${data?.paper_details[0]?.subject_id}`);

  return (
    <div className="body">
      {data?.paperMcqs?.map((ele, index) => (
        <div key={ele.id} className="row clearfix  mb-3">
          <MCQSCol
            col="col-12"
            number={`(${convertToRoman(index + 1)})`}
            text={
              isMath ? (
                <MathJax math={ele.description} />
              ) : (
                parse(`${ele.description}`)
              )
            }
            image={ele.file}
            subject_id={data?.paper_details[0]?.subject_id}
          />
          <MCQSCol
            col="col-3"
            number={"A."}
            text={isMath ? <MathJax math={ele.opt_a} /> : parse(`${ele.opt_a}`)}
            image={ele.opt_a_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_a"}
          />
          <MCQSCol
            col="col-3"
            number={"B."}
            text={isMath ? <MathJax math={ele.opt_b} /> : parse(`${ele.opt_b}`)}
            image={ele.opt_b_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_b"}
          />
          <MCQSCol
            col="col-3"
            number={"C."}
            text={isMath ? <MathJax math={ele.opt_c} /> : parse(`${ele.opt_c}`)}
            image={ele.opt_c_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_c"}
          />
          <MCQSCol
            col="col-3"
            number={"D."}
            text={isMath ? <MathJax math={ele.opt_d} /> : parse(`${ele.opt_d}`)}
            image={ele.opt_d_file}
            isRightOption={showMcqAnswer && ele.option_answer == "opt_d"}
          />
        </div>
      ))}
    </div>
  );
};

// export const BSSMCQSFormat = ({ data }) => {
//   return (
//     <div className="body">
//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>name</th>
//             <th>email</th>
//             <th>role</th>
//             <th style={{ textAlign: "end" }}>ACTIONS</th>
//           </tr>
//         </thead>
//         <tbody>
//           {rows
//             ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//             ?.map((row) => (
//               <tr key={row.id}>
//                 <th scope="row">{row.name}</th>
//                 <td>{row.email}</td>
//                 <td>{row?.rolename}</td>

//                 <td style={{ display: "flex", justifyContent: "flex-end" }}>
//                   <button
//                     type="button"
//                     className="btn btn-warning mr-1"
//                     title="Edit"
//                     onClick={() => history.push(`/users/edit/${row.id}`)}
//                   >
//                     <span className="sr-only">Edit</span>
//                     <i className="fa fa-edit"></i>
//                   </button>
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     title="Delete"
//                     onClick={() => deleteChapter.mutate(row.id)}
//                   >
//                     <span className="sr-only">Delete</span>
//                     <i className="fa fa-trash-o"></i>
//                   </button>
//                 </td>
//               </tr>
//             ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

export const EnglishMCQSFormat = ({ data, showMcqAnswer }) => {
  const mcqs = React.useMemo(() => {
    let mcqsData = [...data.paperMcqs];

    // Object to store grouped MCQs
    const groupedMcqs = {
      1: {},
      2: {},
      3: {},
      4: {},
    };

    // Group MCQs with the same description
    for (const mcq of mcqsData) {
      const mcqFormat = getMCQFormat(`${mcq.chapter_id}`);

      groupedMcqs[mcqFormat.id] = {
        id: mcqFormat?.id,
        statement: mcqFormat?.name,
        children: groupedMcqs[mcqFormat.id]?.children || [],
      };

      groupedMcqs[mcqFormat.id].children.push({
        ...mcq,
        description: mcqFormat.id == 2 ? "" : mcq.description,
      });

      // if (mcqFormat.name in groupedMcqs) {
      //   groupedMcqs[mcqFormat.name].children.push({
      //     ...mcq,
      //     description: mcqFormat.id == 2 ? "" : mcq.description,
      //   });
      // } else {
      //   groupedMcqs[mcqFormat.name] = {
      //     id: mcqFormat.id,
      //     statement: mcqFormat.name,
      //     children: [
      //       { ...mcq, description: mcqFormat.id == 2 ? "" : mcq.description },
      //     ],
      //   };
      // }
    }

    // Loop through each object in the data
    for (const key in groupedMcqs) {
      const obj = groupedMcqs[key];
      const start = startIndex(key, groupedMcqs);

      if (obj.children) {
        obj.children.forEach((child, index) => {
          child.number = index + start;
        });
      }
    }

    return groupedMcqs;
  }, [data]);

  return (
    <div className="body">
      {Object.values(mcqs || {})?.map(
        (ele, index) =>
          !!(ele?.children?.length > 0) && (
            <div key={index} className="row clearfix  mb-3">
              <MCQSCol col="col-12" text={parse(`${ele?.statement}`)} />

              <div className="body" style={{ margin: "0 20px" }}>
                {ele?.children?.map((mcqOpt) => (
                  <div key={`${index}-${mcqOpt?.id}`} className="row">
                    <MCQSCol
                      col="col-12"
                      number={`(${convertToRoman(mcqOpt?.number)})`}
                      text={parse(`${mcqOpt?.description}`)}
                      image={ele?.file}
                    />

                    <MCQSCol
                      col="col-3"
                      number={"A."}
                      text={parse(`${mcqOpt.opt_a}`)}
                      image={mcqOpt.opt_a_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_a"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"B."}
                      text={parse(`${mcqOpt.opt_b}`)}
                      image={mcqOpt.opt_b_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_b"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"C."}
                      text={parse(`${mcqOpt.opt_c}`)}
                      image={mcqOpt.opt_c_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_c"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"D."}
                      text={parse(`${mcqOpt.opt_d}`)}
                      image={mcqOpt.opt_d_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_d"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export const PakStudiesMCQSFormat = ({ data, showMcqAnswer }) => {
  const mcqs = React.useMemo(() => {
    let mcqsData = [...data.paperMcqs];

    // Object to store grouped MCQs
    const groupedMcqs = {
      1: {},
      2: {},
      3: {},
      4: {},
    };

    // Group MCQs with the same description
    for (const mcq of mcqsData) {
      const mcqFormat = getMCQFormat(`${mcq.chapter_id}`);

      groupedMcqs[mcqFormat.id] = {
        id: mcqFormat?.id,
        statement: mcqFormat?.name,
        children: groupedMcqs[mcqFormat.id]?.children || [],
      };

      groupedMcqs[mcqFormat.id].children.push({
        ...mcq,
        description: mcqFormat.id == 2 ? "" : mcq.description,
      });
    }

    // Loop through each object in the data
    for (const key in groupedMcqs) {
      const obj = groupedMcqs[key];
      const start = startIndex(key, groupedMcqs);

      if (obj.children) {
        obj.children.forEach((child, index) => {
          child.number = index + start;
        });
      }
    }

    return groupedMcqs;
  }, [data]);

  return (
    <div className="body">
      {Object.values(mcqs || {})?.map(
        (ele, index) =>
          !!(ele?.children?.length > 0) && (
            <div key={index} className="row clearfix  mb-3">
              <MCQSCol col="col-12" text={parse(`${ele?.statement}`)} />

              <div className="body" style={{ margin: "0 20px" }}>
                {ele?.children?.map((mcqOpt) => (
                  <div key={`${index}-${mcqOpt?.id}`} className="row">
                    <MCQSCol
                      col="col-12"
                      number={`${mcqOpt?.number}.`}
                      text={parse(`${mcqOpt?.description}`)}
                      image={ele?.file}
                    />

                    <MCQSCol
                      col="col-3"
                      number={"(A)"}
                      text={parse(`${mcqOpt.opt_a}`)}
                      image={mcqOpt.opt_a_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_a"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"(B)"}
                      text={parse(`${mcqOpt.opt_b}`)}
                      image={mcqOpt.opt_b_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_b"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"(C)"}
                      text={parse(`${mcqOpt.opt_c}`)}
                      image={mcqOpt.opt_c_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_c"
                      }
                    />
                    <MCQSCol
                      col="col-3"
                      number={"(D)"}
                      text={parse(`${mcqOpt.opt_d}`)}
                      image={mcqOpt.opt_d_file}
                      isRightOption={
                        showMcqAnswer && mcqOpt.option_answer == "opt_d"
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  );
};
export const ComputerStudiesMCQSFormat = ({ data, showMcqAnswer }) => {
  const mcqs = React.useMemo(() => {
    let mcqsData = [...data.paperMcqs];

    // Object to store grouped MCQs
    const groupedMcqs = {
      1: {},
      2: {},
      3: {},
      4: {},
    };

    // Group MCQs with the same description
    for (const mcq of mcqsData) {
      const mcqFormat = getMCQFormat(`${mcq.chapter_id}`);

      groupedMcqs[mcqFormat.id] = {
        id: mcqFormat?.id,
        statement: mcqFormat?.name,
        children: groupedMcqs[mcqFormat.id]?.children || [],
      };

      groupedMcqs[mcqFormat.id].children.push({
        ...mcq,
        description: mcqFormat.id == 2 ? "" : mcq.description,
      });
    }

    // Loop through each object in the data
    for (const key in groupedMcqs) {
      const obj = groupedMcqs[key];
      const start = startIndex(key, groupedMcqs);

      if (obj.children) {
        obj.children.forEach((child, index) => {
          child.number = index + start;
        });
      }
    }

    return groupedMcqs;
  }, [data]);

  return (
    <div className="body">
      {Object.values(mcqs || {})?.map(
        (ele, index) =>
          !!(ele?.children?.length > 0) && (
            <div key={index} className="border-cls clearfix  mb-3">
              {/* <MCQSCol col="col-12" text={parse(`${ele?.statement}`)} /> */}
              <table style={{ width: "100%" }}>
                <tr>
                  <th className="borderBottom borderRight w5Per px-2">1.</th>
                  <th className="px-2 borderBottom w95Per ">
                    Choose the correct answer.
                  </th>
                </tr>
                {ele?.children?.map((mcqOpt) => (
                  <tr key={`${index}-${mcqOpt?.id}`}>
                    <td className="w5Per borderBottom borderRight text-center">
                      <MCQSTab
                        number={`${mcqOpt?.number}.`}
                        image={ele?.file}
                      />
                    </td>
                    <td className="w95Per borderBottom ">
                      <MCQSTab
                        // number={`${mcqOpt?.number}.`}
                        text={parse(`${mcqOpt?.description}`)}
                        image={ele?.file}
                      />
                      <br />
                      <div className="d-flex justify-content-around">
                        <MCQSTab
                          col=""
                          number={"(A)"}
                          text={parse(`${mcqOpt.opt_a}`)}
                          image={mcqOpt.opt_a_file}
                          isRightOption={
                            showMcqAnswer && mcqOpt.option_answer == "opt_a"
                          }
                        />
                        <MCQSTab
                          col=""
                          number={"(B)"}
                          text={parse(`${mcqOpt.opt_b}`)}
                          image={mcqOpt.opt_b_file}
                          isRightOption={
                            showMcqAnswer && mcqOpt.option_answer == "opt_b"
                          }
                        />
                        <MCQSTab
                          col=""
                          number={"(C)"}
                          text={parse(`${mcqOpt.opt_c}`)}
                          image={mcqOpt.opt_c_file}
                          isRightOption={
                            showMcqAnswer && mcqOpt.option_answer == "opt_c"
                          }
                        />
                        <MCQSTab
                          col=""
                          number={"(D)"}
                          text={parse(`${mcqOpt.opt_d}`)}
                          image={mcqOpt.opt_d_file}
                          isRightOption={
                            showMcqAnswer && mcqOpt.option_answer == "opt_d"
                          }
                        />
                      </div>
                    </td>

                    {/* <MCQSTab
                      col="col-12"
                      number={`${mcqOpt?.number}.`}
                      text={parse(`${mcqOpt?.description}`)}
                      image={ele?.file}
                    /> 

                     
                     */}
                  </tr>
                ))}
              </table>
            </div>
          )
      )}
    </div>
  );
};

const getMCQFormat = (chapter) => {
  if (["333", "428"].includes(chapter)) {
    return { id: 1, name: "Choose the correct form of the verb." };
  }
  if (["334", "429"].includes(chapter)) {
    return { id: 2, name: "Choose the correct spellings." };
  }
  if (["332", "430"].includes(chapter)) {
    return {
      id: 3,
      name: "Choose the correct meanings of the underlined words.",
    };
  }
  if (["335", "431"].includes(chapter)) {
    return {
      id: 4,
      name: "Choose the correct option according to the grammar.",
    };
  }

  return {
    id: 5,
    name: "",
  };
};

const startIndex = (id, groupedMcqs) => {
  let totalLength = 1;
  for (let i = 1; i < id; i++) {
    totalLength += groupedMcqs[i]?.children?.length || 0;
  }
  return totalLength;
};
