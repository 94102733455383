import React, { useState, useContext } from "react";

// util
import { setToken, getToken, removeToken } from "utils/token";

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [state, setState] = useState(
    getToken() ? JSON.parse(getToken()) : null
  );

  const onLogin = (data) => {
    const payload = {
      access_token: data.access_token,
      user: data.data.user_details[0],
      permissions: data.data.permissions,
    };
    setState(payload);
    setToken(JSON.stringify(payload));
  };

  const onLogout = () => {
    removeToken();
    setState(null);
  };

  return (
    <AuthContext.Provider value={{ ...state, onLogin, onLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuthContext can only be used inside AuthProvider");
  }

  return context;
}
