import React from "react";

const infoStyle = { border: "1px solid black", padding: "0.1rem 1rem " };
const PaperStudentEntrySindh = ({ subjectCheck, data }) => {
  return (
    <div className={subjectCheck === 16 && `font-weight-bold`}>
      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Subject: {data?.paperMcqs[0]?.subject_name}</div>
        <div>Campus: ________________</div>
        {subjectCheck === 16 && <div>Roll No: ________________</div>}
      </div>
      <div
        className=""
        style={{
          margin: "0 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {subjectCheck === 16 ? (
          <>
            <div>Date: ________________</div>
            <div>Section: ________________</div>
          </>
        ) : (
          <>
            <div>
              Roll No: <span className="mx-2"></span>{" "}
              <span style={infoStyle}></span>
              <span style={infoStyle}></span>
              <span style={infoStyle}></span>
              <span style={infoStyle}></span>
              <span style={infoStyle}></span>
              <span style={infoStyle}></span>
            </div>
            <div>Answer Sheet No: ________________</div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaperStudentEntrySindh;
