import React from "react";

// packages
import moment from "moment";

// hooks
import { useGetNotifications } from "hooks/api/shared";

const FeedCards = () => {
  const { data: notifications } = useGetNotifications();
  return (
    <div className="card">
      <div className="header">
        <h2>Recent Notifications</h2>
      </div>
      <div className="body">
        <ul className="list-unstyled feeds_widget">
          {notifications?.data?.slice(0, 5)?.map((ele) => (
            <li>
              <div className="feeds-left">
                <i className="fa fa-user text-success"></i>
              </div>
              <div className="feeds-body">
                <h4 className="title" style={{ fontSize: "14px" }}>
                  {ele.user_name}
                  <small className="float-right text-muted">
                    {moment(ele.created_at).fromNow()}
                  </small>
                </h4>
                <small>{ele.text}</small>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FeedCards;
