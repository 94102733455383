import React from "react";

const infoStyle = { border: "1px solid #c5c5c5", padding: "8px" };

const PaperStudentEntry = ({ data }) => {
  return (
    <div className="row clearfix" style={{ margin: "0 0px 20px 0px" }}>
      <div className="col-2" style={infoStyle}>
        Name:
      </div>
      <div className="col-6" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        Grade:
      </div>
      <div className="col-1" style={infoStyle}>
        {data?.paperMcqs[0]?.classname}
      </div>
      <div className="col-1" style={infoStyle}>
        Section:
      </div>
      <div className="col-1" style={infoStyle}></div>
      <div className="col-2" style={infoStyle}>
        Subject:
      </div>
      <div className="col-6" style={infoStyle}>
        {data?.paperMcqs[0]?.subject_name}
      </div>
      <div className="col-1" style={infoStyle}>
        Date:
      </div>
      <div className="col-1" style={infoStyle}></div>
      <div className="col-1" style={infoStyle}>
        Roll No:
      </div>
      <div className="col-1" style={infoStyle}></div>
    </div>
  );
};

export default PaperStudentEntry;
