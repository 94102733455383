import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

// packages
import CustomPagination from "components/CustomPagination ";
import TableFilters from "components/TableFilters";

// components
import PageHeader from "components/BreadCrumb";

// hooks
import { useTable } from "hooks/custom/useTable";

// context
import { useAuthContext } from "context/auth.context";

//
import {
  dataReviewUser,
  defaultAdminPermissions,
  defaultEntryPermissions,
} from "constant";
import { CSVLink } from "react-csv";
import {
  useDeleteNumberOfQuiz,
  useGetNumberOfQuizzes,
} from "hooks/api/students";
import NumberOfQuizesFilters from "components/TableFilters/NUmberOfQuizesFilters";

const NumberOfQuizzes = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const [rows, setRows] = useState([]);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const [board, setBoard] = useState();
  const [sClasses, setSClasses] = useState();
  const [subject, setSubject] = useState();

  const {
    data: numberOfQuiz,
    refetch,
    isLoading,
  } = useGetNumberOfQuizzes(board, sClasses, subject);

  useEffect(() => {
    if (board) {
      refetch();
    }
    if (sClasses) {
      refetch();
    }
    if (subject) {
      refetch();
    }
  }, [board, sClasses, subject, refetch]);

  const deleteNumberOfQuiz = useDeleteNumberOfQuiz(refetch);

  const formatDataForCSV = () => {
    return (
      numberOfQuiz?.data?.map((row) => ({
        "No. of Quizzes": row.num_of_quiz,
        Class: row.class_name,
        Board: row.board_name,
        Subjects: row.subject_name,
      })) || []
    );
  };

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Number Of Quizzes"
        Breadcrumb={[{ name: "Number Of Quizzes" }]}
      />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Number Of Quizzes</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink
              filename={"Number of Quizzes.csv"}
              data={formatDataForCSV()}
              asyncOnClick={true}
              onClick={(event, done) => {
                if (!numberOfQuiz?.data?.length) {
                  alert("No data to export");
                  done(false);
                } else {
                  done();
                }
              }}
            >
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export Number of Quizzes</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>

            {defaultEntryPermissions.includes(user.rolename.toLowerCase()) && (
              <Link to="/quizzes/number-of-quizzes/create">
                <button
                  type="button"
                  className="btn btn-success"
                  title="Add Number Of Quizzes"
                >
                  <span className="sr-only">Create Number Of Quizzes</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Add Number Of Quizzes</span>
                </button>
              </Link>
            )}
          </div>
        </div>

        <NumberOfQuizesFilters
          data={numberOfQuiz?.data || []}
          board={board}
          setBoard={setBoard}
          sClasses={sClasses}
          setSClasses={setSClasses}
          subject={subject}
          setSubject={setSubject}
          searchKey="question"
          contextKey="QUESTION_FILTERS"
          isLoading={isLoading}
          showCampus
          showChapters
          showTopics
          showTypes
        />

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>No. of Quizzes</th>
                  <th>Class</th>
                  <th>Board</th>
                  <th>Subjects</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {numberOfQuiz?.data?.length > 0 ? (
                      numberOfQuiz?.data?.map((row) => (
                        <tr key={row.id}>
                          <th scope="row">{row.num_of_quiz}</th>
                          <th scope="row">{row.class_name}</th>
                          <td>{row.board_name}</td>
                          <td>{row.subject_name}</td>

                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {defaultEntryPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-success mx-1"
                                  title="Edit"
                                  onClick={() =>
                                    history.push(
                                      `/quizzes/chapters/${row.id}/${row.board_id}/${row.class_id}/${row.subject_id}`
                                    )
                                  }
                                >
                                  <i className="fa fa-plus mr-1"></i>
                                  <span>Add Chapters</span>
                                </button>
                              )}

                              {defaultEntryPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  title="Edit"
                                  onClick={() =>
                                    history.push(
                                      `/quizzes/number-of-quizzes/edit/${row.id}`
                                    )
                                  }
                                >
                                  <span className="sr-only">Edit</span>
                                  <i className="fa fa-edit"></i>
                                </button>
                              )}

                              {defaultAdminPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-1"
                                  title="Delete"
                                  onClick={() =>
                                    deleteNumberOfQuiz.mutate(row.id)
                                  }
                                >
                                  <span className="sr-only">Delete</span>
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={numberOfQuiz?.data?.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberOfQuizzes;
