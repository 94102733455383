import React, { useState, useEffect } from "react";

// api's hook
import {
  useGetBoards,
  useGetClasses,
  useGetSubjects,
  useGetChapters,
  useGetQuestions,
  useGetTopics,
} from "hooks/api/subject";

import { useAppContext } from "context/app.context";

const TableFilters = ({
  data,
  setRows,
  setLoading,
  searchKey,
  contextKey,
  isLoading,
  showChapters,
  showTopics,
  showTypes,
}) => {
  const { state: appState, dispatch } = useAppContext();

  const [state, setState] = useState(null);
  const [search, setSearch] = useState("");

  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();
  const { data: chapters } = useGetChapters();
  const { data: topics } = useGetTopics();
  const { data: questions } = useGetQuestions();

  useEffect(() => {
    if (isLoading) return;

    if (!!appState[contextKey]) {
      const {
        board_id = null,
        class_id = null,
        subject_id = null,
        chapter_id = null,
        topic_id = null,
        type = null,
        search = "",
      } = appState[contextKey];

      setState({ board_id, class_id, subject_id, chapter_id, topic_id, type });
      setSearch(search);

      const filterArrayData = filterArray({
        board_id,
        class_id,
        subject_id,
        chapter_id,
        topic_id,
        type,
      });
      const searchData = filterArrayData.filter((ele) =>
        ele[searchKey].toLowerCase().includes(search.toLowerCase())
      );

      setRows(searchData);
      setLoading(false);
    } else {
      setRows(data);
      setLoading(false);
    }
  }, [appState[contextKey], data, isLoading]);

  const handleSelectChange = (key, value) => {
    setLoading(true);
    dispatch(contextKey, { ...appState[contextKey], [key]: value });
  };

  const handleSearch = (value) => {
    dispatch(contextKey, { ...appState[contextKey], search: value });
  };

  const handleClear = () => {
    setRows(data);
    setLoading(false);
    dispatch(contextKey, {});
  };

  const removeNullValues = (obj) => {
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  };

  const filterArray = (filterObj) => {
    const obj = removeNullValues({ ...filterObj });

    const filteredArray = data.filter((item) => {
      let match = true;
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (item[key] != obj[key]) {
            match = false;
            break;
          }
        }
      }
      return match;
    });

    return filteredArray;
  };

  return (
    <div className="body">
      <div className="row clearfix">
        <div className="col-4 mb-4 mb-4">
          <CustomSelect
            label="Boards"
            value={state?.board_id || ""}
            onChange={(e) => handleSelectChange("board_id", e.target.value)}
            options={boards?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        <div className="col-4 mb-4">
          <CustomSelect
            label="Classes"
            value={state?.class_id || ""}
            onChange={(e) => handleSelectChange("class_id", e.target.value)}
            options={classes?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        <div className="col-4 mb-4">
          <CustomSelect
            label="Subjects"
            value={state?.subject_id || ""}
            onChange={(e) => handleSelectChange("subject_id", e.target.value)}
            options={subjects?.data?.map((ele) => ({
              value: ele.id,
              label: ele.name,
            }))}
          />
        </div>

        {showChapters && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Chapters"
              value={state?.chapter_id || ""}
              onChange={(e) => handleSelectChange("chapter_id", e.target.value)}
              options={chapters?.data
                ?.filter(
                  (chp) =>
                    chp?.board_id == state?.board_id &&
                    chp?.subject_id == state?.subject_id &&
                    chp?.class_id == state?.class_id
                )
                ?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
            />
          </div>
        )}

        {showTopics && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Topics"
              value={state?.topic_id || ""}
              onChange={(e) => handleSelectChange("topic_id", e.target.value)}
              options={topics?.data
                ?.filter((top) => top?.chapter_id == state?.chapter_id)
                ?.map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
            />
          </div>
        )}

        {showTypes && (
          <div className="col-4 mb-4">
            <CustomSelect
              label="Type"
              value={state?.type || ""}
              onChange={(e) => handleSelectChange("type", e.target.value)}
              options={[
                { value: "short", label: "Short Questions" },
                { value: "long", label: "Long Questions" },
              ]}
            />
          </div>
        )}

        <div className="col-4 mb-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Search</span>
            </div>
            <input
              type={"text"}
              placeholder={`Search...`}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className="form-control"
            />
          </div>
        </div>

        <div className="col-4 mb-4">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              type="button"
              className="btn btn-warning"
              title="Clear All"
              disabled={
                Object.keys(
                  removeNullValues({
                    ...state,
                    search: search == "" ? null : search,
                  }) || {}
                ).length === 0
              }
              onClick={handleClear}
            >
              <span className="sr-only">Clear All</span>
              <i className="fa fa-remove"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFilters;

const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <label className="input-group-text">{label}</label>
      </div>
      <select className="custom-select" value={value} onChange={onChange}>
        <option value="">-- select an option --</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
