import React, { useState } from "react";
import PageHeader from "components/BreadCrumb";

// apis
import { rateReview } from "api/v1/feedback";

// hook
import { useToaster } from "hooks/custom/useToaster";

const Feedback = () => {
  const [activeTab, setActiveTab] = useState(1);

  const onTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="col-lg-8 col-md-12">
      <PageHeader Breadcrumb={[{ name: "Feedback Rate & Review" }]} />
      <div className="card">
        <div className="body">
          <ul className="nav nav-tabs" role="tablist">
            <li className={`nav-item mr-1 active`} role="presentation">
              <a className="nav-link" data-toggle="tab">
                Rate & Review
              </a>
            </li>
          </ul>
          <div className="tab-content" style={{ padding: "40px 20px" }}>
            <RateReview />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

const StarRating = ({ rating, onRatingChange }) => {
  const stars = [1, 2, 3, 4, 5]; // Number of stars

  return (
    <div>
      {stars.map((star) => (
        <span
          key={star}
          className={`fa ${star <= rating ? "fa-star" : "fa-star-o"}`}
          style={{
            color: "#ffa500",
            fontSize: "20px",
            cursor: "pointer",
            marginRight: "4px",
          }}
          onClick={() => onRatingChange(star)}
        ></span>
      ))}
    </div>
  );
};

const RateReview = () => {
  const [areaInput, setAreaInput] = useState("");
  const [rating, setRating] = useState(1);

  const [submeet, setSubmeet] = useState(false);
  const { toasterError, toasterSuccess } = useToaster();

  const createRateReview = async () => {
    if (areaInput == "") {
      return;
    }

    try {
      await rateReview({
        rate_review: areaInput,
        rate_review_stars: rating,
      });
      toasterSuccess(`Review submitted`);
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  return (
    <div id="bacicTabpan-1" className={`tab-pane show active`}>
      <h6>Rate the scale:</h6>
      <br />
      <StarRating rating={rating} onRatingChange={handleRatingChange} />
      <br />
      <h6>Add a review:</h6>
      <form className="ng-untouched ng-dirty ng-invalid">
        <div className="form-group">
          <textarea
            className={`form-control ${
              areaInput === "" && submeet && "parsley-error"
            } `}
            value={areaInput}
            onChange={(e) => setAreaInput(e.target.value)}
          />
          {areaInput === "" && submeet ? (
            <ul className="parsley-errors-list filled" id="parsley-id-29">
              <li className="parsley-required">This value is required.</li>
            </ul>
          ) : null}
        </div>
        <br />
      </form>
      <button
        className="btn btn-primary"
        onClick={() => {
          setSubmeet(true);
          createRateReview();
        }}
      >
        Submit
      </button>
    </div>
  );
};
