import React from "react";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import { useGetRoles } from "hooks/api/admin";
import ImageFile from "components/FormField/ImageFile";
import { useCreateNotification } from "hooks/api/shared";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateBroadCast = () => {
  const [image, setImage] = React.useState({});

  const { data: roles } = useGetRoles();

  const createNotification = useCreateNotification();

  const { handleSubmit, control, formState } = useForm();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage({
      file,
      name: file.name,
      previewUrl: URL.createObjectURL(file),
    });
  };

  async function onSubmit(data) {
    try {
      let body = _.pick(data, ["role", "notification"]);

      const formData = new FormData();
      formData.append(`file`, image?.file || null);
      Object.entries(body).map(([key, value], index) =>
        formData.append(`${key}`, value)
      );

      await createNotification.mutateAsync(formData);
    } catch (err) {}
  }

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Broadcast"
        Breadcrumb={[
          { name: "Broadcast", navigate: "/broadcasts" },
          { name: "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>Create New Broadcast</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.role}
                item={{
                  name: "role",
                  label: "Role",
                  options: roles?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <div className="col-6 mb-4" />
              <FormTextField
                control={control}
                error={formState?.errors?.notification}
                item={{
                  name: "notification",
                  label: "Message",
                  placeholder: "Enter Message",
                  rules,
                }}
              />
              <div className="col-6 mb-4" />
              <div className="col-6  mb-4">
                <ImageFile image={image} onChange={handleImageChange} />
              </div>
              <div className="col-6 mb-4" />
              {!!image?.previewUrl && (
                <div className="col-6 mb-4" style={{ position: "relative" }}>
                  <button
                    type="button"
                    title="Close"
                    className="btn btn-warning"
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                    }}
                    onClick={() => setImage({})}
                  >
                    <span className="sr-only">Close</span>
                    <i className="fa fa-close"></i>
                  </button>
                  <img
                    src={image?.previewUrl}
                    alt="image"
                    style={{ maxWidth: "100%", width: "200px" }}
                  />
                </div>
              )}
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              Create
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateBroadCast;
