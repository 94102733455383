import React from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";

// components
import Layout from "../layout";
import Toaster from "components/Toaster";

// public pages
import Login from "screens/Login";
import Page403 from "screens/Page403";
import Page404 from "screens/Page404";
import PaperPrint from "screens/PaperManager/Paper/Print";

// private routes
import { privateRoutes } from "./paths";

// context
import { useAuthContext } from "context/auth.context";

const AppRoute = () => {
  const { user } = useAuthContext();

  return (
    <div id="wrapper">
      <Switch>
        <PublicRoute
          exact
          path={`/login`}
          component={Login}
          isAuthenticated={!!user}
        />

        <PublicRoute
          exact
          path={`/page403`}
          component={Page403}
          isAuthenticated={!!user}
        />

        <PublicRoute
          exact
          path={`/page404`}
          component={Page404}
          isAuthenticated={false}
        />

        <PublicRoute
          exact
          path={`/papers/:id/print`}
          component={PaperPrint}
          isAuthenticated={false}
        />

        {privateRoutes.map((ele) => (
          <PrivateRoute
            key={ele.path}
            exact
            path={ele.path}
            component={ele.component}
            isAuthenticated={user}
            permissions={ele.permissions}
          />
        ))}

        <Route path="*">
          <Redirect to="/Page404" />
        </Route>
      </Switch>

      <Toaster />
    </div>
  );
};

export default withRouter(AppRoute);

// HOC for public routes
const PublicRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to="/" />
        ) : (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

// HOC for private routes
const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  permissions,
  ...rest
}) => {
  if (!!isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) =>
          permissions?.includes(isAuthenticated?.rolename?.toLowerCase()) ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to="/page403" />
          )
        }
      />
    );
  }

  return <Route {...rest} render={() => <Redirect to="/login" />} />;
};
