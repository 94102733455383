import React from "react";
import { useParams } from "react-router-dom";

// hooks
import { useGetPaper, useGetPunjabPaper } from "hooks/api/paper";

import PaperFormat from "components/PaperFormat/index";

const PaperPrint = () => {
  const { id } = useParams();

  const { data: punjabPaper } = useGetPunjabPaper(id);

  return (
    <div
      className="container-fluid"
      style={{
        background: "#fff",
        width: "100%",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <PaperFormat data={punjabPaper?.data} showMcqAnswer={true} />
    </div>
  );
};

export default PaperPrint;
