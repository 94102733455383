import React from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import {
  useCreateChapter,
  useGetBoards,
  useGetChapter,
  useGetClasses,
  useGetSubjects,
  useUpdateChapter,
} from "hooks/api/subject";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateChapter = () => {
  const { id } = useParams();

  const { data: chapter } = useGetChapter(id);
  const { data: boards } = useGetBoards();
  const { data: classes } = useGetClasses();
  const { data: subjects } = useGetSubjects();

  const createChapter = useCreateChapter();
  const updateChapter = useUpdateChapter();

  const { handleSubmit, reset, control, formState, setValue } = useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data, status: 1 };

      id
        ? await updateChapter.mutateAsync({ id, body })
        : await createChapter.mutateAsync(body);
    } catch (err) {}
  }

  React.useEffect(() => {
    if (chapter?.data?.[0]) {
      let values = _.pick(chapter?.data?.[0], [
        "name",
        "board_id",
        "class_id",
        "lesson_no",
        "subject_id",
      ]);

      values = {
        name: values.name,
        boardId: values.board_id,
        classId: values.class_id,
        subjectId: values.subject_id,
        lessonNo: values.lesson_no,
      };

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
    }
  }, [chapter]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Chapter"
        Breadcrumb={[
          { name: "Chapters", navigate: "/chapters" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} Chapter</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormSelect
                control={control}
                error={formState?.errors?.boardId}
                item={{
                  name: "boardId",
                  label: "Board",
                  options: boards?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.classId}
                item={{
                  name: "classId",
                  label: "Class",
                  options: classes?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.subjectId}
                item={{
                  name: "subjectId",
                  label: "Subject",
                  options: subjects?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />
              <FormSelect
                control={control}
                error={formState?.errors?.lessonNo}
                item={{
                  name: "lessonNo",
                  label: "Chapter #",
                  options: chapterNo?.map((ele) => ({
                    value: ele,
                    label: ele,
                  })),
                  rules,
                }}
              />
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Chapter Name",
                  placeholder: "Enter Chapter Name",
                  rules,
                }}
              />
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateChapter;

const chapterNo = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50"
];
