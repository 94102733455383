import client from "../api-config";

export function login(body) {
  return client.post("/api/user/login", body);
}

export function changePassword(body) {
  return client.post("/api/user/changePassword", body);
}

export function bssLogin(body) {
  return client.post("/api/campususer/login", body);
}
