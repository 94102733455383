import React from "react";

// packages
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function QuilTextEditor({
  placeholder = "Write",
  onChange = () => {},
  value = "",
}) {
  return (
    <ReactQuill
      value={value}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      modules={modules}
      preserveWhitespace={true}
      className="react-quill"
    />
  );
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }],
    ["blockquote", "code-block"],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
