import React, { useState } from "react";

// components
import FormTextField from "components/FormField/TextField";
import MCQSModal from "components/Modal/Mcqs";

// packages
import _ from "lodash";
import parse from "html-react-parser";
import Select from "react-dropdown-select";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const MCQS = ({
  formState,
  control,
  board_id,
  class_id,
  subject_id,
  selectedMcqs,
  setSelectedMcqs,
  randomSelection,
  setRandomSelection,
  chaptersOptions,
  selectedMcqsChapters,
  setSetSelectedMcqsChapters,
}) => {
  const [selectedChapters, setSelectedChapters] = useState([]);

  return (
    <div className="card">
      <div className="header">
        <h2>MCQS</h2>
      </div>

      <div className="body">
        <div className="row clearfix">
          <div className="col-12  mb-4 form-group">
            <div className="input-group-prepend">
              <label className="input-group-text">Select Chapters</label>
            </div>
            <Select
              className="js-states "
              placeholder=""
              options={chaptersOptions}
              values={chaptersOptions.filter((ele) =>
                selectedMcqsChapters.includes(ele.value)
              )}
              // values={chaptersOptions.filter((ele) =>
              //   selectedChapters.includes(ele.value)
              // )}
              multi={true}
              searchable={true}
              onChange={(values) => {
                // setSelectedChapters(values.map((ele) => ele.value));
                setSetSelectedMcqsChapters(values.map((ele) => ele.value));
              }}
            />
          </div>

          {!randomSelection?.mcqs && (
            <div className="col-6">
              <MCQSModal
                board_id={board_id}
                class_id={class_id}
                subject_id={subject_id}
                selectedChapters={selectedMcqsChapters}
                selectedMcqs={selectedMcqs}
                setSelectedMcqs={setSelectedMcqs}
                btnDisabled={!!randomSelection?.mcqs}
              />
            </div>
          )}

          {/* <div className="col-6">
            <CustomCheckbox
              label="Random"
              name="checkbox-mcqs"
              defaultChecked={randomSelection?.mcqs || false}
              value={randomSelection?.mcqs || false}
              onChange={(e) =>
                setRandomSelection({
                  ...randomSelection,
                  mcqs: e.target.checked,
                })
              }
            />
          </div> */}

          {!!randomSelection?.mcqs && (
            <FormTextField
              control={control}
              error={formState?.errors?.noOfMcqs}
              item={{
                name: "noOfMcqs",
                type: "Number",
                label: "Select No of MCQS",
                placeholder: "Select No of MCQS",
              }}
            />
          )}
        </div>

        {!randomSelection?.mcqs && selectedMcqs?.length > 0 && (
          <MCQSFormatting states={selectedMcqs} setStates={setSelectedMcqs} />
        )}
      </div>
    </div>
  );
};

export default MCQS;

const MCQSCol = ({ col, number, text }) => {
  return (
    <p
      className={col}
      style={{ display: "flex", alignItems: "flex-start", margin: 0 }}
    >
      <span style={{ marginRight: "5px", fontWeight: "500" }}>{number}</span>
      <span>{text}</span>
    </p>
  );
};

const CustomCheckbox = ({ name, defaultChecked, onChange, value, label }) => {
  return (
    <div className="form-group">
      <label className="fancy-checkbox">
        <input
          id={name}
          name={name}
          type="checkbox"
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

const MCQSFormatting = ({ states, setStates }) => {
  return (
    <div className="body">
      {states?.map((ele, index) => (
        <div
          key={ele.id}
          className="row clearfix"
          style={{ marginBottom: "20px" }}
        >
          <div col="col-2">
            <CustomCheckbox
              label=""
              defaultChecked={true}
              value={ele.id}
              onChange={(value) =>
                setStates(states.filter((mcq) => mcq.id !== ele.id))
              }
            />
          </div>

          <MCQSCol
            col="col-10"
            number={`${index + 1}:`}
            text={parse(`${ele.description}`)}
          />

          <MCQSCol col="col-3" number={"a:"} text={parse(`${ele.opt_a}`)} />
          <MCQSCol col="col-3" number={"b:"} text={parse(`${ele.opt_b}`)} />
          <MCQSCol col="col-3" number={"c:"} text={parse(`${ele.opt_c}`)} />
          <MCQSCol col="col-3" number={"d:"} text={parse(`${ele.opt_d}`)} />
        </div>
      ))}
    </div>
  );
};
