import React from "react";
import { useParams } from "react-router-dom";

// components
import PageHeader from "components/BreadCrumb";
import FormTextField from "components/FormField/TextField";
import FormSelect from "components/FormField/Select";

// packages
import { useForm } from "react-hook-form";
import _ from "lodash";

// api's hook
import { useGetCampuses } from "hooks/api/campus";
import { useGetSchools } from "hooks/api/shared";
import { useGetRoles } from "hooks/api/admin";
import {
  useCreateCampusUser,
  useGetCampusUser,
  useUpdateCampusUser,
} from "hooks/api/campusUser";

const rules = {
  required: {
    value: true,
    message: "This field is required!",
  },
};

const CreateCampus = () => {
  const { id } = useParams();

  const { data: campusUser } = useGetCampusUser(id);

  const { data: schools } = useGetSchools();
  const { data: roles } = useGetRoles();
  const { data: campuses } = useGetCampuses();

  const createCampusUser = useCreateCampusUser();
  const updateCampusUser = useUpdateCampusUser();

  const { handleSubmit, reset, control, formState, setValue } = useForm();

  async function onSubmit(data) {
    try {
      const body = { ...data };

      id
        ? await updateCampusUser.mutateAsync({ id, body })
        : await createCampusUser.mutateAsync(body);

      reset();
    } catch (err) {}
  }

  React.useEffect(() => {
    if (campusUser?.data?.[0]) {
      let values = _.pick(campusUser?.data?.[0], ["name", "email", "code"]);

      values = { ...values, role: values.roleid };

      Object.entries(values).forEach(([key, val]) => {
        setValue(`${key}`, val);
      });
    }
  }, [campusUser]);

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Create Campus User"
        Breadcrumb={[
          { name: "New Users", navigate: "/campus-users" },
          { name: id ? "Edit" : "Create" },
        ]}
      />

      <div className="card">
        <div className="header">
          <h2>{id ? "Edit" : "Create"} New User</h2>
        </div>

        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row clearfix">
              <FormTextField
                control={control}
                error={formState?.errors?.name}
                item={{
                  name: "name",
                  label: "Name",
                  placeholder: "Enter name",
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.email}
                item={{
                  name: "email",
                  label: "Email",
                  placeholder: "Enter email",
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.role}
                item={{
                  name: "role",
                  label: "Role",
                  options: roles?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              <FormSelect
                control={control}
                error={formState?.errors?.campusId}
                item={{
                  name: "campusId",
                  label: "Campus",
                  options: campuses?.data?.map((ele) => ({
                    value: ele.id,
                    label: ele.name,
                  })),
                  rules,
                }}
              />

              <FormTextField
                control={control}
                error={formState?.errors?.code}
                item={{
                  name: "code",
                  label: "Campus Code",
                  placeholder: "Enter campus code",
                  rules,
                }}
              />
            </div>
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary ml-auto"
            >
              {id ? "Update" : "Create"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCampus;
