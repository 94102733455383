import React from "react";

// components
import PaperHeader from "../PaperHeader";
import { MCQSFormat } from "./MCQS";
import { SindhiShortQuestions, SindhiLongQuestions } from "./Questions";
import { convertToRoman } from "utils/convertToRoman";
import PaperStudentEntrySindh from "../SindhUrdu/PaperStudentEntrySindh";

const IslamiyatFormat = ({ data, showMcqAnswer }) => {
  const subjectCheck = data?.paper_details[0]?.subject_id;
  const isMcqExist = !!(data?.paperMcqs.length > 0);
  const isShortQuestionsExist = !!(
    data?.section[0]?.paperShortQuestions?.length > 0
  );
  const isLongQuestionsExist = !!(
    data?.section[1]?.paperLongQuestions?.length > 0
  );
  const is10 = data?.paper_details[0]?.class_id === 2;
  const boardType = data?.paper_details[0].paper_board_type_id;
  return (
    <div style={{ color: "black", fontSize: "24px" }}>
      {isMcqExist && (
        <>
          <div className="body">
            <PaperHeader data={data} />
            {/*  */}
            <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />
            <div className="noto-nastaliq-sindhi" style={{ direction: "rtl" }}>
              <div className="row clearfix   mb-3">
                <div className="col-6 text-right">
                  وقت:{data?.paper_details[0].objective_time}
                </div>
                <div className="col-6 ">
                  مارڪون: {data?.paper_details[0].objective_marks}
                </div>
              </div>

              <div className="row clearfix   mb-3">
                <div className="col-12 text-center text-decoration-cls">
                  حصو ”الف“ (گهڻ چونڊائتا سوال(
                </div>
              </div>

              <MCQInstruction
                boardType={boardType}
                subjectCheck={subjectCheck}
              />
              <div className="col-12 text-right  ">
                <> سوال 1:{data?.paper_details[0].objective_note}</>
              </div>

              <MCQSFormat is10={is10} subjectCheck={subjectCheck} data={data} />
            </div>
          </div>

          <div className="page-break"></div>
        </>
      )}

      {(isShortQuestionsExist || isLongQuestionsExist) && (
        <div className="body">
          <PaperHeader data={data} />
          <div className="noto-nastaliq-sindhi" style={{ direction: "rtl" }}>
            <div className="row clearfix  mb-3">
              <div className="col-6 text-right">
                وقت:
                {data?.paper_details[0].subjective_time}
              </div>

              <div className="col-6">
                مارڪون:
                {data?.paper_details[0].subjective_marks}
              </div>
            </div>
            <div className={`row clearfix mb-2  `}>
              <div className="col-10 text-right">
                <span>هدايتون:{data?.section[0].text}</span>
              </div>
            </div>

            <div className="row clearfix  mb-3">
              <div className="col-7 ">حصو ”ب“ (مختصر جواب جا سوال)</div>
              <div className="col-5 ">(ڪل مارڪون {data?.section[0].marks})</div>
            </div>

            {!!isShortQuestionsExist && (
              <>
                <SindhiShortQuestions
                  subject_id={subjectCheck}
                  class_id={data?.paper_details[0]?.class_id}
                  data={data?.section[0]}
                  isMcqExist={isMcqExist}
                />
              </>
            )}
            <div className="row clearfix  mb-3">
              <div className="col-7 ">حصو (ج) (تفصيلي جواب جا سوال)</div>
              <div className="col-5 ">(ڪل مارڪون {data?.section[1].marks})</div>
            </div>
            {!!isLongQuestionsExist && (
              <>
                <SindhiLongQuestions
                  subject_id={subjectCheck}
                  is10={is10}
                  boardType={boardType}
                  data={data?.section[1]}
                  startIndex={data?.section[0]?.paperShortQuestions.length || 0}
                  isMcqExist={isMcqExist}
                />
              </>
            )}
          </div>
        </div>
      )}

      {isMcqExist && showMcqAnswer && (
        <>
          <div className="page-break"></div>
          <div className="body">
            <PaperHeader data={data} />
            <div className="noto-nastaliq-sindhi" style={{ direction: "rtl" }}>
              <PaperStudentEntrySindh subjectCheck={subjectCheck} data={data} />

              <>
                <div className="row clearfix   mb-3">
                  <div className="col-6 text-right">کل وقت: ۳ گھنٹے</div>
                  <div className="col-6 ">
                    کل نشانات: {data?.paper_details[0].paper_marks}
                  </div>
                  <div className="col-3 text-right">
                    مختص وقت:{data?.paper_details[0].objective_time}
                  </div>
                  <div className="col-6 text-center  text-decoration-cls">
                    حصہ الف(لازمی) (کثیر الانتخابی سوالات)
                  </div>
                  <div className="col-2 text-right  ">
                    کل نشانات:
                    {data?.paper_details[0].objective_marks}
                  </div>
                </div>
              </>

              <div className="col-12 text-right  ">
                سوال نمبر۱۔الف){data?.paper_details[0].objective_note}
              </div>

              <MCQSFormat data={data} showMcqAnswer={showMcqAnswer} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IslamiyatFormat;

const MCQInstruction = ({ boardType, subjectCheck }) => {
  const instructions = [
    {
      line: "سڀني سوالن جا جواب گهربل آهن.",
    },
    {
      line: "سوال ڪاپيءَ ۾ نه لکو. پر پرچي مطابق سوال جو صحيح نمبر لکي ان جي سامهون صرف جواب ڏيو.",
    },
    {
      line: "هر هڪ سوال هڪ هڪ مارڪ رکيل آهي.",
    },
  ];
  return (
    <div className="row clearfix mb-3">
      <>
        <div className={`col-1 mb-2`}>هدايتون</div>
        <div className="">
          {instructions.map((ins, index) => {
            return (
              <div className="d-flex">
                <p>{`(${convertToRoman(index + 1)})`}</p>
                <p>{ins.line}</p>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};
