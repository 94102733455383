import React from "react";

// packages
import _ from "lodash";
import parse from "html-react-parser";

export const CustomTable = ({ rows, rowSelect, column }) => {
  return (
    <div
      className="body table-responsive"
      style={{ paddingTop: 0, paddingBottom: 0 }}
    >
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Select</th>
            <th>Question</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <tr key={row.id}>
              <td style={{ width: "60px" }}>
                <CustomCheckbox
                  label=""
                  defaultChecked={true}
                  value={row.id}
                  onChange={() => rowSelect(row.id)}
                />
              </td>
              <td>{parse(`${row[`${column}`]}`)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const CustomCheckbox = ({ defaultChecked, onChange, value, label }) => {
  return (
    <div className="form-group">
      <label className="fancy-checkbox">
        <input
          name="checkbox"
          type="checkbox"
          defaultChecked={defaultChecked}
          value={value}
          onChange={onChange}
        />
        <span>{label}</span>
      </label>
    </div>
  );
};

export const CustomTextField = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">{label}</span>
      </div>
      <input
        type={type || "text"}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="form-control"
      />
    </div>
  );
};

export const BackNextBtn = ({
  stepper,
  setStepper,
  onSave,
  nextBtnDisabled,
  saveBtnDisabled,
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
      <button
        className="btn btn-secondary"
        disabled={stepper > 1 ? false : true}
        onClick={() => setStepper(stepper - 1)}
      >
        Back
      </button>
      {stepper <= 3 ? (
        <button
          className="btn btn-primary"
          disabled={nextBtnDisabled}
          onClick={() => setStepper(stepper + 1)}
        >
          Next
        </button>
      ) : (
        <button
          className="btn btn-success"
          disabled={saveBtnDisabled}
          onClick={onSave}
        >
          Save
        </button>
      )}
    </div>
  );
};
