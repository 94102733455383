// third party
import { useQuery } from "@tanstack/react-query";

// hooks
import { getDashboardStats } from "api/v1/dashboard";

export const useGetDashboardStats = () => {
  return useQuery({
    queryKey: ["dashboard", "stats"],
    queryFn: () => getDashboardStats(),
    refetchOnWindowFocus: false,
  });
};
