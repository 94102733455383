import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// packages
import parse from "html-react-parser";
import MathJax from "react-mathjax-preview";
import CustomPagination from "components/CustomPagination ";
import TableFilters from "components/TableFilters";

// components
import PageHeader from "components/BreadCrumb";

// hooks
import { useDeleteQuestion, useGetQuestions } from "hooks/api/subject";
import { useTable } from "hooks/custom/useTable";

// context
import { useAuthContext } from "context/auth.context";

//
import {
  dataReviewUser,
  defaultAdminPermissions,
  defaultEntryPermissions,
} from "constant";
import { CSVLink } from "react-csv";
import StudentFilters from "components/TableFilters/StudentFilters";
import { useDeleteQuiz, useGetStudentQuizesList } from "hooks/api/students";
import { formatTime } from "utils/Helper";

const Quizes = () => {
  const history = useHistory();
  const { user } = useAuthContext();

  const { id, class_id } = useParams();

  const [rows, setRows] = useState([]);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTable();

  const [board, setBoard] = useState();
  const [sClasses, setSClasses] = useState();
  const [subject, setSubject] = useState();
  const [campuses, setcampuses] = useState(); 
  const [sub_quiz_num, setSubQuizNum] = useState(); 
  const [stdName, setStdName] = useState(); 
  
  const {
    data: quizzes,
    refetch,
    isLoading,
  } = useGetStudentQuizesList(id, class_id, campuses, sub_quiz_num);

  useEffect(() => {
    if (board) {
      refetch();
    }
    if (sClasses) {
      refetch();
    }
    if (campuses) {
      refetch();
    }
  }, [board, sClasses, campuses, refetch]);

  const deleteQuiz = useDeleteQuiz(refetch);

  const formatDataForCSV = () => {
    return (
      quizzes?.data?.map((row) => ({
        Subject: row.quiz_title,
        Name: row.student.name,
        Percentage: `${row.percentage}%`,
        "Quiz Type": row.quizType,
        "Quiz Number": row.sub_quiz_num,
        "Quiz Time": formatTime(row.quiz_time),
        "Time Spent": formatTime(row.time_spend),
        Marks: `${row.quiz_obt_marks}/${row.quiz_marks}`,
        Class: row.classType == "1" ? "IX - 9th" : "X - 10th",
        Email: row.student.email,
      })) || []
    );
  };

  useEffect(() => {
    if (quizzes?.data?.length > 0) {
      setStdName(quizzes.data[0].student.name);
    }
  }, [quizzes]); 

  // Use the length of quizzes.data as the total count
  const totalCount = quizzes?.data?.length || 0;

  // Slice the data based on the current page and rows per page
  const paginatedData = quizzes?.data?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  ) || [];

  return (
    <div className="container-fluid">
      <PageHeader
        HeaderText="Student Quizzes"
        Breadcrumb={[
          { name: "Students", navigate: "/students" },
          { name: "Quizzes" },
        ]}
      />

      <div className="card">
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>{stdName} Quizzes</h2>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CSVLink
              filename={`${stdName} Quizzes.csv`}
              data={formatDataForCSV()}
              asyncOnClick={true}
              onClick={(event, done) => {
                if (!quizzes?.data?.length) {
                  alert("No data to export");
                  done(false);
                } else {
                  done();
                }
              }}
            >
              <button type="button" className="btn btn-primary">
                <span className="mx-1">Export {stdName} Quizzes</span>
                <i className="fa fa-download"></i>
              </button>
            </CSVLink>

            {defaultEntryPermissions.includes(user.rolename.toLowerCase()) && (
              <Link to="/students/create">
                <button
                  type="button"
                  className="btn btn-success"
                  title="Add Student"
                >
                  <span className="sr-only">Create Student</span>
                  <i className="fa fa-plus mr-1"></i>{" "}
                  <span>Add Student</span>
                </button>
              </Link>
            )}
          </div>
        </div>

        <StudentFilters
          data={quizzes?.data || []}
          board={board}
          setBoard={setBoard}
          campuses={campuses}
          sClasses={sClasses}
          setSClasses={setSClasses}
          setSubject={setSubject}
          setcampuses={setcampuses}
          searchKey="question"
          contextKey="QUESTION_FILTERS"
          isLoading={isLoading}
          showCampus
          showChapters
          showTopics
          showTypes
        />

        <div className="body">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Name</th>
                  <th>Percentage</th>
                  <th>Quiz Type</th>
                  <th>Quiz Number</th>
                  <th>Marks</th>
                  <th>Class</th>
                  <th>Email</th>
                  <th style={{ textAlign: "end" }}>ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{
                        textAlign: "center",
                        height: "30vh",
                        width: "100%",
                      }}
                      className="visible-lg"
                    >
                      <i className="fa fa-spinner fa-4x fa-spin"></i>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <>
                    {paginatedData.length > 0 ? (
                      paginatedData.map((row) => (
                        <tr key={row.id}>
                          <th scope="row">{row.quiz_title}</th>
                          <th scope="row">{row.student.name}</th>
                          <td>{row.percentage}%</td>
                          <td>{row.quizType}</td>
                          <td>{row.sub_quiz_num}</td>
                          <td>
                            {row.quiz_obt_marks}/{row.quiz_marks}
                          </td>
                          <td>
                            {row.classType == "1" ? "IX - 9th" : "X - 10th"}
                          </td>
                          <td>{row.student.email}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {/* <button
                                type="button"
                                className="btn btn-primary mr-1"
                                title="View"
                                onClick={() =>
                                  history.push(`/questions/${row.id}`)
                                }
                              >
                                <span className="sr-only">View</span>
                                <i className="fa fa-eye"></i>
                              </button> */}

                              

                              {defaultAdminPermissions.includes(
                                user.rolename.toLowerCase()
                              ) && (
                                <button
                                  type="button"
                                  className="btn btn-danger ml-1"
                                  title="Delete"
                                  onClick={() => deleteQuiz.mutate(row.id)}
                                >
                                  <span className="sr-only">Delete</span>
                                  <i className="fa fa-trash-o"></i>
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            height: "30vh",
                            fontSize: "1.5rem",
                            width: "100%",
                          }}
                        >
                          Oops! No data found
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="body" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CustomPagination
            count={totalCount} // Use the length of quizzes.data as the total count
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default Quizes;