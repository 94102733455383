import React from "react";


// components
import PageHeader from "components/BreadCrumb";
import TutorialPDF from "../../assets/pdf/Tutorial-LodgeComplaint.pdf"

export default function LodgeComplaint() {
  return (
    <div className="container-fluid">
      <PageHeader
        Breadcrumb={[
          { name: "Utilities", navigate: "/taketour" },
          { name: "Lodge a Complaint" },
        ]}
      />
      <div className="card">
        <div className="header">
          <h2>Lodge a Complaint</h2>
        </div>
        <div className="body">
          <p>This is a pdf tutorial of how to lodge a complaint in the system system </p>
          <iframe width="1000" height="800" src={TutorialPDF} title="Tutorual - How to lodge a Complaint" frameborder="0" allow="accelerometer; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
}
