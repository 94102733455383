import React, { useState, useEffect } from "react";

// packages
import { Modal } from "react-bootstrap";

// api
import { deleteAssignTopic } from "api/v1/subject";

// hook
import { useToaster } from "hooks/custom/useToaster";

const DeleteTopics = ({ data, topics }) => {
  const [modal, setModal] = useState(false);
  const [method, setMethod] = useState();
  const [newTopic, setNewTopic] = useState();

  const { toasterError, toasterSuccess } = useToaster();

  const handleClose = () => setModal(false);

  const deleteTopic = async () => {
    try {
      const body = newTopic ? { assignTopicId: newTopic } : {};
      await deleteAssignTopic({
        id: data.id,
        body,
      });
      toasterSuccess(`Topic Deleted`);
      handleClose();
    } catch (err) {
      toasterError(`${err.errMsg}`);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-danger ml-1"
        title="Delete"
        onClick={() => setModal(true)}
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash-o"></i>
      </button>

      <Modal size="md" centered show={modal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Are you sure to delete this topic?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="header">
            <h6>Select topic deletion method</h6>
          </div>

          <div className="fancy-radio">
            <label>
              <input
                name="method"
                type="radio"
                value="all"
                checked={method === "all"}
                onChange={(e) => {
                  setMethod(e.target.value);
                  setNewTopic();
                }}
              />
              <span>
                <i></i>Delete topic with their referrences
              </span>
            </label>
          </div>
          <div className="fancy-radio">
            <label>
              <input
                name="method"
                type="radio"
                value="specific"
                checked={method === "specific"}
                onChange={(e) => setMethod(e.target.value)}
              />
              <span>
                <i></i>Assign topic referrences to another topic
              </span>
            </label>
          </div>

          {method === "specific" && (
            <CustomSelect
              label="Select Topic"
              value={newTopic || ""}
              onChange={(e) => setNewTopic(e.target.value)}
              options={topics
                ?.filter(
                  (ele) =>
                    ele.chapter_id == data.chapter_id && ele.id != data.id
                )
                .map((ele) => ({
                  value: ele.id,
                  label: ele.name,
                }))}
            />
          )}

          <button
            className="btn btn-warning mt-4"
            style={{ width: "100%" }}
            onClick={deleteTopic}
            disabled={
              !(method === "all" || (method === "specific" && newTopic))
            }
          >
            Done
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteTopics;

const CustomSelect = ({ label, options, value, onChange }) => {
  return (
    <div className="input-group mt-4">
      <div className="input-group-prepend">
        <label className="input-group-text">{label}</label>
      </div>
      <select className="custom-select" value={value} onChange={onChange}>
        <option value="">-- select an option --</option>
        {options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
