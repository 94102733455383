import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.min.css";
import {Helmet} from "react-helmet";

// packages
import { connect } from "react-redux";

// components
import Navbar from "./Navbar";
import SideBar from "./SideBar";

// redux
import { onPressSideMenuToggle, onPressNotification } from "../actions";

const Layout = ({
  themeColor,
  toggleNotification,
  onPressNotification,
  onPressSideMenuToggle,
  children,
}) => {
  document.body.classList.add(themeColor);

  let res = window.location.pathname;
  let baseUrl = process.env.PUBLIC_URL;
  baseUrl = baseUrl.split("/");
  res = res.split("/");
  const printRoute = res.length > 0 ? [res[1], res[3]] : "/";
  res = res.length > 0 ? res[baseUrl.length] : "/";
  res = res ? res : "/";
  const activeKey = res;

  if (
    activeKey.toLowerCase() === "login" ||
    activeKey.toLowerCase() === "page403" ||
    activeKey.toLowerCase() === "page404" ||
    (printRoute[0] === "papers" && printRoute[1] === "print")
  ) {
    return children;
  }

  return (
    <>
    <Helmet>
    <link href="https://www.dafontfree.net/embed/b2xkLWVuZ2xpc2gtdGV4dC1tdC1yZWd1bGFyJmRhdGEvMjYvby8xMzY5NDQvT0xELnR0Zg" rel="stylesheet" type="text/css"/>
    </Helmet>
      <div>
        <Navbar
          onPressSideMenuToggle={onPressSideMenuToggle}
          onPressNotification={onPressNotification}
          toggleNotification={toggleNotification}
        />
        <SideBar activeKey={activeKey} />
      </div>
      <div id="main-content">
        <div
          onClick={() => {
            document.body.classList.remove("offcanvas-active");
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  themeColor: PropTypes.string.isRequired,
  toggleNotification: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ navigationReducer }) => {
  const { themeColor, toggleNotification } = navigationReducer;
  return {
    themeColor,
    toggleNotification,
  };
};

export default connect(mapStateToProps, {
  onPressNotification,
  onPressSideMenuToggle,
})(Layout);
